import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
//import spinner
import React from "react";
import ChangeEmail from "../../components/Account/ChangeEmail";
import ChangePassword from "../../components/Account/ChangePassword";

const MyAccount: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="tabs/home" />
          </IonButtons>

          <IonTitle>Account</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <ChangeEmail />
        <ChangePassword />
      </IonContent>
    </IonPage>
  );
};

export default MyAccount;
