/* eslint-disable jsx-a11y/anchor-is-valid */
//tempalte for component in react
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import {
  doc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  where,
  writeBatch,
  collection,
  getDoc,
} from "firebase/firestore";
import { closeCircleOutline } from "ionicons/icons";
import React, { useContext, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { UserContext } from "../../lib/context";
import {
  validateEmail,
  validateFirstName,
  validatePhone,
  validateLastName,
} from "../../lib/helpers";

import screen_shot from "./csv_screen_shot.png";

export default function AddGroupCSV() {
  const [groupCSV, setGroupCSV] = React.useState<string>();
  const [file, setFile] = React.useState<File>();
  const [groups, setGroups] = React.useState([]);
  const { user, profile } = useContext(UserContext);
  const [groupId, setGroupId] = React.useState<string>();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState("");
  const [showTemplate, setShowTemplate] = useState(false);
  const [validGroup, setValidGroup] = useState(true);
  const [present] = useIonToast();

  const validateRowFormat = (line) => {
    //console.log("Length", line.length);
    if (line.length === 16) {
      return true;
    }
    alert("Invalid CSV format for row: " + line.toString());
    return false;
  };

  const validateId = (id) => {
    //make sure id does not have any spaces, tabs, or new lines
    if (id.includes(" ") || id.includes("\t") || id.includes("\n")) {
      alert("Invalid ID: " + id);
      return false;
    }
    return true;
  };

  const validateEmailFormat = (email: string) => {
    if (validateEmail(email)) {
      return true;
    }
    alert("Invalid email format for row: " + email);
    return false;
  };

  const validatePhoneFormat = (phone: string) => {
    if (validatePhone(phone) || phone === "") {
      return true;
    }
    alert("Invalid phone format for row: " + phone);
    return false;
  };

  const validateFirstNameFormat = (firstName: string) => {
    if (validateFirstName(firstName) || firstName === "") {
      return true;
    }
    alert("Invalid first name format for row: " + firstName);
    return false;
  };

  const validateLastNameFormat = (lastName: string) => {
    if (validateLastName(lastName) || lastName === "") {
      return true;
    }
    alert("Invalid last name format for row: " + lastName);
    return false;
  };

  const validateDateOfBirthFormat = (dateOfBirth: string) => {
    var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
    //console.log("Date of birth", dateOfBirth);

    if (dateOfBirth === "") {
      alert("DOB is required " + dateOfBirth);
      return false;
    } else if (!dateOfBirth.match(date_regex)) {
      alert(
        "Invalid date of birth format for row: " + dateOfBirth + " (MM/DD/YYYY)"
      );
      return false;
    }
    return true;
  };

  const validateAccess = (access: string) => {
    if (access === "" || access === undefined) {
      alert("Access is required");
      return false;
    } else if (
      access === "fullAccess" ||
      access === "facescanOnly" ||
      access === "telemedicineOnly"
    ) {
      return true;
    }
    alert("Invalid access format for row: " + access);
    return false;
  };

  const validateHasProduct = (hasProduct: string) => {
    if (hasProduct === "" || hasProduct === undefined) {
      return true;
    } else if (hasProduct === "Yes" || hasProduct === "No") {
      return true;
    }
    alert("Invalid hasProduct format for row: " + hasProduct);
    return false;
  };

  const checkIfUserExists = (email: string) => {
    const q = query(
      collection(getFirestore(), "triada_members"),
      where("email", "==", email)
    );

    getDocs(q).then((querySnapshot) => {
      if (querySnapshot.size > 0) {
        alert(
          "User already exists: " +
            email +
            " invite for them will not be created"
        );
      }
    });
  };

  const addLeadingZerosToDate = (date: string) => {
    var date_array = date.split("/");
    var month = date_array[0];
    var day = date_array[1];
    var year = date_array[2];

    if (month.length === 1) {
      month = "0" + month;
    }
    if (day.length === 1) {
      day = "0" + day;
    }

    return month + "/" + day + "/" + year;
  };

  const addMembersToDB = (csvFile) => {
    var valid = true;
    console.log("top");
    const groupIDandName = groupId.split("%");
    let reader = new FileReader();

    reader.readAsText(file);

    reader.onload = function () {
      const csv = reader.result;
      const fileContent = csv.toString();
      const fileRows = fileContent.split("\n");
      var lineno = 0;
      const db = getFirestore();
      const batch = writeBatch(db);

      fileRows.forEach((row) => {
        if (row !== "" && lineno !== 0) {
          const rowArray = row.split(",");
          const externalID = rowArray[0];
          const walmartHealthId = rowArray[1];
          const email = rowArray[2];
          const phone = rowArray[3];
          const firstName = rowArray[4];
          const lastName = rowArray[5];
          const dob = addLeadingZerosToDate(rowArray[6]);
          const addr1 = rowArray[7];
          const addr2 = rowArray[8];
          const city = rowArray[9];
          const state = rowArray[10];
          const zip = rowArray[11];
          const hasCoaching = rowArray[12] === "Yes" ? true : false;
          const hasFacescan = rowArray[13] === "Yes" ? true : false;
          const hasTelemedicine =
            rowArray[14].replace(/(\r \n|\n|\r)/gm, "") === "Yes"
              ? true
              : false;

          const hasQuestis =
            rowArray[15].replace(/(\r \n|\n|\r)/gm, "") === "Yes"
              ? true
              : false;

          var access;

          if (!hasFacescan && !hasTelemedicine && !hasCoaching) {
            access = "fullAccess";
          } else if (hasFacescan && !hasTelemedicine && !hasCoaching) {
            access = "facescanOnly";
          } else if (!hasFacescan && hasTelemedicine && !hasCoaching) {
            access = "telemedicineOnly";
          } else {
            access = "fullAccess";
          }

          if (
            validateRowFormat(rowArray) &&
            validateEmailFormat(email) &&
            validatePhoneFormat(phone) &&
            validateFirstNameFormat(firstName) &&
            validateLastNameFormat(lastName) &&
            validateDateOfBirthFormat(dob) &&
            validateHasProduct(rowArray[12]) &&
            validateHasProduct(rowArray[13]) &&
            validateHasProduct(rowArray[14].replace(/(\r \n|\n|\r)/gm, "")) &&
            validateHasProduct(rowArray[15].replace(/(\r \n|\n|\r)/gm, "")) &&
            validateId(externalID) &&
            validateId(walmartHealthId)
          ) {
            var dobTimeStamp = new Date(dob);

            const userRef = doc(db, "triada_members", email.toLowerCase());
            const member = {
              firstName: firstName || "",
              lastName: lastName || "",
              dob: dob || "",
              dobTimeStamp: dobTimeStamp || "",
              externalID: externalID || "",
              addr1: addr1 || "",
              addr2: addr2 || "",
              city: city || "",
              state: state || "",
              zip: zip || "",
              email: email.toLocaleLowerCase(),
              phone: phone || "",
              groupID: groupIDandName[0],
              groupName: groupIDandName[1],
              coach: user.uid,
              hasCoaching: hasCoaching,
              hasFacescan: hasFacescan,
              hasTelemedicine: hasTelemedicine,
              hasQuestis: hasQuestis,
              access: access || "",
              walmartHealthPlanCode: groupIDandName[2],
            };

            if (walmartHealthId !== "") {
              member["walmartHealthId"] = walmartHealthId;
            }

            console.log("memberrrr", member);
            batch.set(userRef, member);
          } else {
            presentToast(
              "Invalid row: " + rowArray.toString(),
              "danger",
              "bottom"
            );
            valid = false;
          }
        }
        lineno++;
      });

      if (valid) {
        console.log("Add group to DB");
        batch.commit().then(() => {
          presentToast(
            `Successfully added ${lineno - 2} members`,
            "success",
            "top"
          );
        });
      } else {
        presentToast(
          "Unable to add group to system. Please check file and try again",
          "danger",
          "top"
        );
      }
    };
  };

  const presentToast = (message, color, position) => {
    present({
      message: message,
      color: color,
      duration: 0,
      position: position,
      buttons: [
        {
          icon: closeCircleOutline,
          role: "cancel",
        },
      ],
    });
  };

  const checkIfCSVFile = (csvFile) => {
    if (csvFile.type === "text/csv") {
      return true;
    }
    return false;
  };

  const addGroupCSV = async (e: { preventDefault: () => void; target }) => {
    e.preventDefault();

    if (groupId === undefined) {
      presentToast("Please select a group", "danger", "top");
      return;
    } else if (!checkIfCSVFile(file)) {
      presentToast("Please select a CSV file", "danger", "top");
      return;
    } else {
      addMembersToDB(file);
    }

    e.target.reset();
  };

  // // this is what handles when the from is submited
  // const addGroupCSV = async (e: { preventDefault: () => void; target }) => {
  //   e.preventDefault();

  //   let reader = new FileReader();

  //   reader.readAsText(file);

  //   reader.onload = function () {
  //     const csv = reader.result;
  //     const batch = writeBatch(getFirestore());
  //     var lineNum = 0;

  //     try {
  //       if (groupId === undefined || groupId === "") {
  //         alert("Please select a group");
  //         throw new Error("Please select a group to add the users to");
  //       }

  //       csv
  //         .toString()
  //         .split("\n")
  //         .forEach(async function (line) {
  //           if (line !== "" && lineNum !== 0) {
  //             const lineArray = line.split(",");
  //             const email = lineArray[0];
  //             const phone = lineArray[1];
  //             const firstName = lineArray[2];
  //             const lastName = lineArray[3];
  //             const dob = lineArray[4];
  //             const addr1 = lineArray[5];
  //             const addr2 = lineArray[6];
  //             const state = lineArray[7];
  //             const city = lineArray[8];
  //             const zip = lineArray[9].replace(/(\r \n|\n|\r)/gm, "");

  //             const userDoc = doc(getFirestore(), "triada_members", email);

  //             if (
  //               validateRowFormat(lineArray) &&
  //               validateEmailFormat(email) &&
  //               validatePhoneFormat(phone) &&
  //               validateFirstNameFormat(firstName) &&
  //               validateLastNameFormat(lastName) &&
  //               validateDateOfBirthFormat(dob)
  //             ) {
  //               var dobTimeStamp;

  //               if (dob !== "") {
  //                 dobTimeStamp = new Date(dob) || "";
  //               } else {
  //                 dobTimeStamp = "";
  //               }

  //               const groupIDandName = groupId.split("%");

  //               const member = {
  //                 firstName: firstName || "",
  //                 lastName: lastName || "",
  //                 dob: dob || "",
  //                 dobTimeStamp: dobTimeStamp || "",
  //                 addr1: addr1 || "",
  //                 addr2: addr2 || "",
  //                 city: city || "",
  //                 state: state || "",
  //                 zip: zip || "",
  //                 email: email,
  //                 phone: phone || "",
  //                 groupID: groupIDandName[0],
  //                 groupName: groupIDandName[1],
  //                 coach: user.uid,
  //               };

  //               checkIfUserExists(email);

  //               batch.set(userDoc, member);
  //             } else {
  //               setValidGroup(false);
  //               throw new Error("Invalid row format, returning");
  //             }
  //           }
  //           lineNum++;
  //         });

  //       if (validGroup) {
  //         console.log('Adding to db')
  //         batch
  //           .commit()
  //           .then(() => {
  //             setToastMessage("Successfully added new members!");
  //             setToastColor("success");
  //             setShowToast(true);
  //           })
  //           .catch((error) => {
  //             setToastMessage("Error adding members: " + error.message);
  //             setToastColor("danger");
  //             setShowToast(true);
  //           });
  //       }
  //       else {
  //         console.log('Invalid group, not adding to db')
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   reader.onerror = function () {
  //     console.log(reader.error);
  //   };

  //   e.target.reset();
  // };

  // const addGroupCSV = async (e: { preventDefault: () => void; target }) => {
  //   e.preventDefault();

  //   let reader = new FileReader();

  //   reader.readAsText(file);

  //   reader.onload = function () {
  //     const csv = reader.result;
  //     const batch = writeBatch(getFirestore());

  //     csv
  //       .toString()
  //       .split("\n")
  //       .forEach(function (line) {
  //         if (line !== "") {
  //           const userDoc = doc(getFirestore(), "triada_members", uuidv4());

  //           const memberEmail = line.replace(/[\r\n]+/gm, "");

  //           const groupdIDandName = groupCSV.split("%");

  //           batch.set(userDoc, {
  //             coach: user.uid,
  //             email: memberEmail,
  //             groupID: groupdIDandName[0],
  //             groupName: groupdIDandName[1],
  //           });
  //         }
  //       });
  //     //batch.commit();
  //   };

  //   reader.onerror = function () {
  //     console.log(reader.error);
  //   };

  //   e.target.reset();
  // };

  //fetch specialities from firebase
  const fetchGroups = async () => {
    const q = query(
      collection(getFirestore(), "groups"),
      where("active", "==", true),
      orderBy("group", "asc")
    );

    var groupsData = [];

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      groupsData.push(doc.data());
    });

    // sort groups by group name alphabetically
    groupsData.sort((a, b) => {
      if (a.group < b.group) {
        return -1;
      }
      if (a.group > b.group) {
        return 1;
      }
      return 0;
    });


    setGroups(groupsData);
  };

  const listGroups = groups.map((group) => (
    <IonSelectOption
      key={group.groupID}
      value={
        group.groupID + "%" + group.group + "%" + group.walmartHealthPlanCode
      }
    >
      {group.group}
    </IonSelectOption>
  ));

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>Add Group CSV</IonCardTitle>
        <a className="link" onClick={() => setShowTemplate(true)}>
          Template File
        </a>
      </IonCardHeader>
      <IonCardContent>
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          duration={3000}
          position="top"
          color={toastColor}
        />
        <form onSubmit={addGroupCSV}>
          <IonItem lines="none">
            <IonLabel position="stacked">Group</IonLabel>
            <IonSelect
              onFocus={(e) => fetchGroups()}
              cancelText="Cancel"
              okText="Okay"
              onIonChange={(e) => {
                setGroupId(e.detail.value);
              }}
            >
              {listGroups}
            </IonSelect>
          </IonItem>

          <IonItem>
            <IonLabel position="stacked">Group CSV</IonLabel>
            <input
              required
              type={"file"}
              onChange={(e) => setFile(e.target.files[0])}
            ></input>
          </IonItem>
          <div id="add-group-csv-help"></div>

          <IonButton type="submit">Upload CSV</IonButton>
        </form>
      </IonCardContent>

      <IonModal isOpen={showTemplate}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Template Download</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setShowTemplate(false)}>
                Close
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <p className="ion-margin">
            Only Column that is required is email and DOB. Make sure to follow
            the format of the template when uploading groups. Only add Walmart
            Health ID if they are already an existing WH user. Use external ID
            if they are coming from Triada system, otherwise leave blank. For
            yes no questions make sure to put 'Yes' or 'No' in the column.
          </p>
          <img src={screen_shot}></img>
          <IonButton
            className="ion-margin"
            href="https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/helpers%2Fgroup_template_v2.csv?alt=media&token=b641e3fb-f73e-4264-9525-23a3686567f4"
          >
            Download Template File
          </IonButton>
        </IonContent>
      </IonModal>
    </IonCard>
  );
}
