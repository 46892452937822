//import spinner
import { IonCol, IonGrid, IonLabel, IonRow } from "@ionic/react";
import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const GoalsDailyGraph = ({ goalData }) => {
  const getPercentageComplete = (goal) => {
    if (goal.completed === true) {
      return 100;
    } else {
      return 0;
    }
  };

  const getPercentSuccess = (goal) => {
    if (goal.completed === true) {
      return 100;
    } else {
      return 0;
    }
  };

  return (
    <>
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonLabel color="primary" className="ion-padding">
              {" "}
              Completion{" "}
            </IonLabel>

            <div
              style={{ width: 100, height: 100 }}
              className="progress-circle"
            >
              <CircularProgressbar
                value={getPercentageComplete(goalData)}
                text={`${getPercentageComplete(goalData)}%`}
                styles={buildStyles({
                  // Rotation of path and trail, in number of turns (0-1)
                  rotation: 0.25,

                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: "butt",

                  // Text size
                  textSize: "16px",

                  // How long animation takes to go from one percentage to another, in seconds
                  pathTransitionDuration: 0.5,

                  // Can specify path transition in more detail, or remove it entirely
                  // pathTransition: 'none',

                  // Colors
                  textColor: "#456673",
                  trailColor: "#d6d6d6",
                  backgroundColor: "#456673",
                  pathColor: "#456673",
                })}
              />
            </div>
          </IonCol>
          <IonCol>
            <IonLabel color="success" className="ion-padding">
              {" "}
              Success{" "}
            </IonLabel>

            <div
              style={{ width: 100, height: 100 }}
              className="progress-circle"
            >
              <CircularProgressbar
                value={getPercentSuccess(goalData)}
                text={`${getPercentSuccess(goalData)}%`}
                styles={buildStyles({
                  // Rotation of path and trail, in number of turns (0-1)
                  rotation: 0.25,

                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: "butt",

                  // Text size
                  textSize: "16px",

                  // How long animation takes to go from one percentage to another, in seconds
                  pathTransitionDuration: 0.5,

                  // Can specify path transition in more detail, or remove it entirely
                  // pathTransition: 'none',

                  // Colors
                  textColor: "#07dc1d",
                  trailColor: "#d6d6d6",
                  backgroundColor: "#456673",
                  pathColor: "#07dc1d",
                })}
              />
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>

      <br></br>
    </>
  );
};

export default GoalsDailyGraph;
