import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCardContent,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonMenuToggle,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./Affiliates.css";
//import spinner
import React, { useEffect } from "react";
import { collection, doc, getDocs, getFirestore, setDoc } from "@firebase/firestore";
import ReportButton from "../../components/Affiliate/ReportButton";

const Affiliates: React.FC = () => {
  const [affiliates, setAffiliates] = React.useState([]);

  const fetchAffiliates = async () => {
    const affiliatesList = [];

    // get all docs from affiliates
    const querySnapshot = await getDocs(collection(getFirestore(), "affiliates"));

    // loop through docs and add to affiliates array
    querySnapshot.forEach((doc) => {
      affiliatesList.push(doc.data());
      console.log(doc.data());
    });

    // set affiliates state
    setAffiliates(affiliatesList);
    
  }

  useEffect(() => {
    fetchAffiliates();
  }, []);

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const RenderAffiliates = () => {
    let affiliatesList = [];
    affiliates.forEach((affiliate, index) => {
      affiliatesList.push(
        <>
          <IonItem key={affiliate.code}>
            <IonLabel>{affiliate.fullName}</IonLabel>
            <IonLabel className="ion-text-wrap">{affiliate.email}</IonLabel>
            <IonLabel>{affiliate.phone}</IonLabel>
            <IonLabel className="ion-text-wrap">{`https://member.alliedwellness.com/?afl=${affiliate.id}&createAccountOpen=true`}</IonLabel>
            <IonLabel>
              <IonButton
                onClick={() =>
                  copyToClipboard(
                    `https://member.alliedwellness.com/?afl=${affiliate.id}&createAccountOpen=true`
                  )
                }
              >
                Copy
              </IonButton>
            </IonLabel>
           
            <IonLabel>
              <ReportButton code={affiliate.id} name={affiliate.fullName} />
            </IonLabel>
          </IonItem>
        </>
      );
    });

    if (affiliatesList.length == 0) {
      return <div>No Affiliates</div>;
    }

    return <>{affiliatesList}</>;
  };

  const [affiliateOpen, setAffiliateOpen] = React.useState(false);

  const [affiliateFullName, setAffiliateFullName] = React.useState("");
  const [affiliateEmail, setAffiliateEmail] = React.useState("");
  const [affiliatePhone, setAffiliatePhone] = React.useState("");

  const formSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // generate random 5 didget all caps code
    const code = Math.random().toString(36).substring(2, 7).toUpperCase();



    const affiliate = {
      fullName: affiliateFullName,
      email: affiliateEmail,
      phone: affiliatePhone,
      id: code,
    };

    // add to collection affiliates in firebase
    setDoc(doc(getFirestore(), "affiliates", code), affiliate).then(() => {
      setAffiliateEmail("");
      setAffiliateFullName("");
      setAffiliatePhone("");
      setAffiliateOpen(false);
      // fetchAffiliates();
      // add affiliate to affiliates array
      setAffiliates([...affiliates, affiliate]);
    });


  }



  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuToggle>
              <IonMenuButton />
            </IonMenuToggle>
          </IonButtons>
          <IonTitle>Affiliates</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonButton
          className="ion-margin"
          onClick={() => setAffiliateOpen(true)}
        >
          Add New Affiliate
        </IonButton>
        <IonModal
          isOpen={affiliateOpen}
          onDidDismiss={() => setAffiliateOpen(false)}
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton onClick={() => setAffiliateOpen(false)}>
                  Close
                </IonButton>
              </IonButtons>
              <IonTitle>Add New Affiliate</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <form onSubmit={formSubmit}>
              <IonItem>
                <IonLabel position="floating">Full Name</IonLabel>
                <IonInput
                  type="text"
                  value={affiliateFullName}
                  onIonChange={(e) => setAffiliateFullName(e.detail.value!)}
                  required
                />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Email</IonLabel>
                <IonInput
                  type="email"
                  value={affiliateEmail}
                  onIonChange={(e) => setAffiliateEmail(e.detail.value!)}
                  required
                />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Phone</IonLabel>
                <IonInput
                  type="text"
                  value={affiliatePhone}
                  onIonChange={(e) => setAffiliatePhone(e.detail.value!)}
                />
              </IonItem>
              <IonButton type="submit" shape="round" className="ion-margin">Submit</IonButton>
            </form>
          </IonContent>
        </IonModal>
        <IonCardContent>
          <IonItem>
            <IonLabel>
              <b>Name</b>
            </IonLabel>
            <IonLabel>
              <b>Email</b>
            </IonLabel>
            <IonLabel>
              <b>Phone</b>
            </IonLabel>
            <IonLabel>
              <b>Link</b>
            </IonLabel>
            <IonLabel>
              <b>Open Link</b>
            </IonLabel>
            <IonLabel>
              <b>Report</b>
            </IonLabel>
          </IonItem>
          <RenderAffiliates />
        </IonCardContent>
      </IonContent>
    </IonPage>
  );
};

export default Affiliates;
