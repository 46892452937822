import {
  IonButton,
  IonIcon,
  useIonAlert,
} from "@ionic/react";
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { MemberUidContext } from "../../../lib/context/MemberUidContext";
import { fetchGetUser, fetchUpdateUser } from "../../../API/memberAPI";
import { userType } from "../../../Types/user";
import { callOutline } from "ionicons/icons";

export default function AddChangePhoneBTN() {
  const memberUid = useContext(MemberUidContext);
  const [getUser, setGetUser] = useState<userType[]>([]);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [presentAlert] = useIonAlert();

  const fetchGetUserInfo = useCallback(async () => {
    const getUserInfo = await fetchGetUser(memberUid);
    setGetUser(getUserInfo);
  }, [memberUid]);

  useEffect(() => {
    fetchGetUserInfo();
  }, [fetchGetUserInfo]);

  const updateMemberPhoneNumber = async (phoneNumber) => {
    const phoneRegex = /^\d{3}-\d{3}-\d{4}$/; 
    // Example phone number format: xxx-xxx-xxxx
    if (!phoneRegex.test(phoneNumber)) {
      alert("Invalid Phone Number. Please enter a valid phone number in the format XXX-XXX-XXXX");
      return;
    }
  
    const updatedUser = {
      ...getUser[0],
      phone: phoneNumber,
    };
  
    try {
      await fetchUpdateUser(memberUid, updatedUser);
      console.log("Phone number changed successfully");
      window.location.reload();
    } catch (error) {
      alert("Phone number change failed: " + error.message);
    }
  };
  

  return (
    <IonButton
      onClick={() =>
        presentAlert({
          header: "Change member phone number",
          inputs: [
            {
              name: "phoneNumber",
              placeholder: "XXX-XXX-XXXX",
              type: "tel",
            },
          ],
          buttons: [
            {
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
              handler: () => {},
            },
            {
              text: "Ok",
              handler: (alertData) => {
                updateMemberPhoneNumber(alertData.phoneNumber);
              },
            },
          ],
        })
      }
    >
      <IonIcon icon={callOutline} />
    </IonButton>
  );
}
