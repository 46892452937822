import { IonButton } from "@ionic/react";
import "./style.css";
import {
  query,
  collection,
  where,
  getFirestore,
  getDocs,
} from "@firebase/firestore";
import React from "react";

import { CSVLink } from "react-csv";

type Props = {
  code: string;
  name: string;
};

const ReportButton: React.FC<Props> = ({ code, name }) => {
  const [memberData, setMemberData] = React.useState([]);

  const getAllMembers = async () => {
    var members = [];

    const q = query(
      collection(getFirestore(), "users"),
      where("affiliateCode", "==", code)
    );

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      const memberObj = {
        affiliateCode: doc.data().affiliateCode,
        affiliateName: name,
        id: doc.id,
        name: doc.data().firstName + " " + doc.data().lastName,
        email: doc.data().email,
        phone: doc.data().phone,
        accountType: doc.data().accountType,
        active: doc.data().active ? "Active" : "Inactive",
        subscriptionType: doc.data().subscriptionType,
      };
      members.push(memberObj);
    });

    // console.log(members);
    setMemberData(members);
  };

  if (!code) {
    return null;
  }

  if (memberData.length > 0) {
    return (
      <CSVLink
        data={memberData}
        className="downloadButton"
        filename={`${code}_AffiliateReport.csv`}
      >
        Download
      </CSVLink>
    );
  }

  return (
    <>
      <IonButton onClick={getAllMembers}>Report</IonButton>
    </>
  );
};

export default ReportButton;
