import { getDoc, doc, getFirestore } from "@firebase/firestore";
import "./style.css";
import { useEffect, useState } from "react";
import { IonCard, IonLabel, IonTitle } from "@ionic/react";

type Props = {
  profile;
};

const Template: React.FC<Props> = ({ profile }) => {
  console.log("AWDWDWDWD", profile);

  const [freeTrial, setFreeTrial] = useState({});

  const fetchFreeTrial = async () => {
    getDoc(doc(getFirestore(), "freeTrials", profile.uid))
      .then((doc) => {
        if (doc.exists()) {
          console.log("Document data:", doc.data());
          setFreeTrial(doc.data());
        } else {
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };

  useEffect(() => {
    fetchFreeTrial();
  }, []);

  const formatDate = (date) => {
    const d = new Date(date?.seconds * 1000);

    const year = d.getFullYear();
    const month = d.getMonth() + 1;
    const day = d.getDate();

    return `${month}/${day}/${year}`;
  };

  return (
    <IonCard>
      <IonLabel>
        <IonTitle>Account Details</IonTitle>
        <h2>
          Type: {profile.accountType !== "individual" ? "Group" : "Individual"}
        </h2>
        <h2>
          {profile.subscriptionType === "freeTrial" &&
            "Subscription: Free Trial"}
        </h2>
        <h2>{profile.subscriptionType === "group" && "Subscription: Group"}</h2>
        <h2>
          {profile.subscriptionType === "freeTrialOver" &&
            "Subscription: Free Trial Over"}
        </h2>
        <h2>
          {profile.subscriptionType === "paid" &&
            "Subscription: Paid"}
        </h2>
        <h2>
          {profile.subscriptionType === "freeTrial" &&
            `Free Trial Over: ${formatDate(freeTrial["endDate"])}`}
        </h2>

        {/* <h2>Account Type: {profile.accountType}</h2> */}
      </IonLabel>
    </IonCard>
  );
};

export default Template;
