import {
  IonList,
  IonCard,
  IonCardTitle,
  IonBadge,
  IonItem,
  IonLabel,
} from "@ionic/react";

import { useEffect, useState } from "react";
import { MetricsDataGroupTypes } from "../../../../Types/metrics";
import { fetchSelectedGroupMetrics } from "../../../../API/metrics/SelectedGroupAPI";
import TerminateGroupButton from "./BTN/TerminateGroup";

export default function GroupMetrics(props) {
  const [SelectedGroupMetrics, setSelectedGroupMetrics] =
    useState<MetricsDataGroupTypes | null>(null);
  console.log("props", props.group);
  useEffect(() => {
    async function fetchMetrics() {
      const response = await fetchSelectedGroupMetrics(props.group);
      setSelectedGroupMetrics(response);
    }
    fetchMetrics();
  }, [props.group]);

  return (
    <>
      <IonCard color={"secondary"}>
        <IonCardTitle>
          <IonCardTitle>
            {SelectedGroupMetrics?.groupName
              ? SelectedGroupMetrics.groupName.toUpperCase()
              : "NO GROUP SELECTED"}
            <IonItem>
              <IonBadge
                slot="end"
                color={
                  SelectedGroupMetrics?.groupActive ? "success" : "warning"
                }
              >
                {SelectedGroupMetrics?.groupActive ? "ACTIVE" : "NON-ACTIVE"}
              </IonBadge>
              Group ID: {props?.group ? props.group : "N/A"}
            </IonItem>
          </IonCardTitle>
        </IonCardTitle>

        {SelectedGroupMetrics && (
          <>
            <IonList>
              <IonItem>
                <IonBadge slot="end">
                  {SelectedGroupMetrics.totalGroupUsers}
                </IonBadge>
                <IonLabel>Total</IonLabel>
              </IonItem>
              <IonItem>
                <IonBadge slot="end">
                  {SelectedGroupMetrics.totalGroupActiveUsers}
                </IonBadge>
                <IonLabel>Active</IonLabel>
              </IonItem>
              <IonItem>
                <IonBadge slot="end">
                  {SelectedGroupMetrics.totalGroupNonActiveUsers}
                </IonBadge>
                <IonLabel>Non-Active</IonLabel>
              </IonItem>
              <IonItem>
                <IonBadge slot="end">
                  {SelectedGroupMetrics.totalGroupCompletedHRA}
                </IonBadge>
                <IonLabel>Completed HRA</IonLabel>
              </IonItem>
              <IonItem>
                <IonBadge slot="end">
                  {SelectedGroupMetrics.totalGroupNonCompletedHRA}
                </IonBadge>
                <IonLabel>Non-Completed HRA</IonLabel>
              </IonItem>
              <IonItem>
                <IonBadge slot="end">
                  {SelectedGroupMetrics.totalGroupMonthlyCompliant}
                </IonBadge>
                <IonLabel>This Month Compliant</IonLabel>
              </IonItem>
              {SelectedGroupMetrics?.groupActive ? (
                <TerminateGroupButton group={props.group} />
              ) : null}
            </IonList>
          </>
        )}
      </IonCard>
    </>
  );
}
