import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  IonCard,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonPopover,
  IonRow,
} from "@ionic/react";
import { desktopOutline, logoAndroid, logoApple } from "ionicons/icons";
import moment from "moment";
import "./memberhistory.css";
import { MemberUidContext } from "../../../lib/context/MemberUidContext";
import { NewNotificationType } from "../../../Types/notification";
import { trackingTYPES } from "../../../Types/tracking";
import {
  fetchMemberNotifications,
  fetchMemberTracking,
} from "../../../API/memberAPI";

export default function MemberHistory() {
  // Get the current member UID from the context
  const memberUid = useContext(MemberUidContext);

  // Set up state variables for notifications and tracking data
  const [notifications, setNotifications] = useState<NewNotificationType[]>([]);
  const [tracking, setTracking] = useState<trackingTYPES[]>([]);

  // Fetch the member's notifications data from the API
  const fetchNotifications = useCallback(async () => {
    const notificationData = await fetchMemberNotifications(memberUid);
    setNotifications(notificationData);
  }, [memberUid]);

  // Fetch the member's tracking data from the API
  const fetchTracking = useCallback(async () => {
    const trackingData = await fetchMemberTracking(memberUid);
    setTracking(trackingData);
  }, [memberUid]);

  // Use useEffect to call the fetchNotifications function when the component mounts
  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  // Use useEffect to call the fetchTracking function when the component mounts
  useEffect(() => {
    fetchTracking();
  }, [fetchTracking]);

  return (
    <>
      <IonCard>
        <IonGrid>
          {tracking.map((item) => (
            <IonRow key={item.id}>
              <IonCol>
                <IonRow>
                  <IonCardTitle
                    id="deviceLastUse-hover-trigger"
                    style={{ cursor: "pointer" }}
                  >
                    {item.ios ? <IonIcon icon={logoApple} /> : null}&nbsp;
                    {item.android ? <IonIcon icon={logoAndroid} /> : null}&nbsp;
                    {item.desktop ? <IonIcon icon={desktopOutline} /> : null}
                    &nbsp;
                  </IonCardTitle>
                  <IonPopover
                    trigger="deviceLastUse-hover-trigger"
                    triggerAction="click"
                  >
                    <IonRow>
                      {item.ios ? <IonIcon icon={logoApple} /> : null}&nbsp;
                      {item.iosLastUse
                        ? moment(item.iosLastUse.toDate()).fromNow()
                        : "No Ios"}
                    </IonRow>
                    <IonRow>
                      {item.android ? <IonIcon icon={logoAndroid} /> : null}
                      &nbsp;
                      {item.androidLastUse
                        ? moment(item.androidLastUse.toDate()).fromNow()
                        : "No Android"}
                    </IonRow>
                    <IonRow>
                      {item.desktop ? <IonIcon icon={desktopOutline} /> : null}
                      &nbsp;
                      {item.desktopLastUse
                        ? moment(item.desktopLastUse.toDate()).fromNow()
                        : "No Desktop"}
                    </IonRow>
                  </IonPopover>
                </IonRow>
              </IonCol>

              <IonRow>
                <IonCardTitle>
                  Last Online: &nbsp;
                  {item.lastUse
                    ? moment(item.lastUse.toDate()).fromNow()
                    : "Unknown"}
                </IonCardTitle>
              </IonRow>
            </IonRow>
          ))}
          <IonRow>
            {notifications.map((notification) => (
              <>
                <div
                  key={notification.id}
                  className="memberhistory-row-notification"
                >
                  <div>{notification.msgType}</div>
                  {notification.timeStamp && (
                    <div>
                      {moment(notification.timeStamp.toDate()).fromNow()}
                    </div>
                  )}
                </div>
              </>
            ))}
          </IonRow>
        </IonGrid>
      </IonCard>
    </>
  );
}
