import React, { useContext } from "react";
import { IonButton, IonIcon, useIonAlert } from "@ionic/react";
import { MemberUidContext } from "../../../lib/context/MemberUidContext";
import axios from "axios";
import { validateEmail } from "../../../lib/helpers";
import { UserContext } from "../../../lib/context";
import { mailOutline } from "ionicons/icons";

export default function ChangeMemberEmail() {
  const memberUid = useContext(MemberUidContext);
  const { user, profile } = useContext(UserContext);
  const [presentAlert] = useIonAlert();
  

  const updateMemberEmail = async (email) => {
    console.log(email, memberUid);
    if (!validateEmail(email)) {
      alert("Please enter a valid email address.");
      return;
    }

    const data = { uid: memberUid, newUserEmail: email };
    const config = {
      method: "post",
      url: `https://${process.env.REACT_APP_CF_URL}.cloudfunctions.net/Login-ChangeEmail`,
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        alert(response.data);
        console.log(response.data);
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <IonButton
        onClick={() =>
          presentAlert({
            header: "Change member email",
            inputs: [
              {
                name: "email",
                placeholder: "joe@mail.com",
                type: "text",
              },
            ],
            buttons: [
              {
                text: "Cancel",
                role: "cancel",
                cssClass: "secondary",
                handler: () => {},
              },
              {
                text: "Ok",
                handler: (alertData) => {
                  updateMemberEmail(alertData.email)
                    .then(() => {
                      console.log("Email changed successfully");
                    })
                    .catch((error) => {
                      alert("Email change failed: " + error.message);
                    });
                },
              },
            ],
          })
        }
      >
        <IonIcon icon={mailOutline} />
      </IonButton>
    </>
  );
}
