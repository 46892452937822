import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonInput,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { doc, getFirestore, writeBatch } from "firebase/firestore";
import React from "react";
import { validateEmail } from "../../lib/helpers";

export default function AddCoach() {
  const [coach, setCoach] = React.useState<string>();

  // this is what handles when the from is submited
  const addCoach = async (e: { preventDefault: () => void; target }) => {
    e.preventDefault();

    if (validateEmail(coach) && coach !== "") {
      const userDoc = doc(getFirestore(), "coaches", coach);

      // Commit both docs together as a batch write.
      const batch = writeBatch(getFirestore());
      batch.set(userDoc, {});
      batch.commit();
    }

    e.target.reset();
  };

  const helpEmail = () => {
    const addCoachButton = document.getElementById("add-coach-button");
    const addCoachHelp = document.getElementById("add-coach-help");

    if (coach === "") {
      addCoachButton.removeAttribute("disabled");
      addCoachHelp.innerHTML = "";
    } else if (!validateEmail(coach)) {
      addCoachButton.setAttribute("disabled", "true");
      addCoachHelp.innerHTML = "Please enter a valid email";
    } else {
      addCoachHelp.innerHTML = "";
    }
  };

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>Add Coach</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <form onSubmit={addCoach}>
          <IonItem>
            <IonLabel position="stacked">Coach Email</IonLabel>
            <IonInput
              required
              placeholder=""
              onIonChange={(e) => setCoach(e.detail.value)}
              onIonBlur={(e) => helpEmail()}
            ></IonInput>
            <div id="add-coach-help"></div>
          </IonItem>
          <IonButton id="add-coach-button" type="submit">
            Add Coach
          </IonButton>
        </form>
      </IonCardContent>
    </IonCard>
  );
}
