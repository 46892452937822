import { useEffect, useState } from "react";
import "./style.css";
import { doc, getDoc, getFirestore } from "@firebase/firestore";
import { IonItem, IonLabel, IonList } from "@ionic/react";

type Props = {
  uid: string;
};

const BodeeMemberHealthData: React.FC<Props> = ({ uid }) => {
  const [profileData, setProfileData] = useState(null);

  const fetchQuizData = async () => {
    // fetch quiz data from firestore
    const docRef = doc(getFirestore(), "users", uid);

    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // console.log("Document data:", docSnap.data());
      setProfileData(docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      // console.log("No such document!");
    }
  };

  useEffect(() => {
    fetchQuizData();
  }, []);

  if (profileData == undefined || profileData == null || profileData == "") {
    return <div>No Profile Data</div>;
  }

  return (
    <>
      <div>
        <h2>Member Health Data</h2>
        <>
          <IonList>
            <IonItem>
              <b>Gender: </b>
              {profileData.gender === "male" ? " Male" : " Female"}
            </IonItem>
            <IonItem>
              <b>Height: </b>
              {profileData.heightFt}Ft. {profileData.heightIn}In.
            </IonItem>
            <IonItem><b>Weight:{" "} </b> {profileData.weight} Lbs.</IonItem>
            {profileData.quitSmoking ? (
              <IonItem>
                <b>Quit Smoking</b>: Yes
              </IonItem>
            ) : null}

            {profileData.manageStress ? (
              <IonItem>
                <b>Manage Stress</b>: Yes
              </IonItem>
            ) : null}

            {profileData.loseWeight ? (
              <IonItem>
                <b>Loose Weight</b>: Yes
              </IonItem>
            ) : null}

            {profileData.increaseActivity ? (
              <IonItem>
                <b>Increase Activity</b>: Yes
              </IonItem>
            ) : null}

            {profileData.improveMentalHealth ? (
              <IonItem>
                <b>Improve Mental Health</b>: Yes
              </IonItem>
            ) : null}

            {profileData.getBetterSleep ? (
              <IonItem>
                <b>Get Better Sleep</b>: Yes
              </IonItem>
            ) : null}

            {profileData.eatBetter ? (
              <IonItem>
                <b>Eat Better</b>: Yes
              </IonItem>
            ) : null}

            {profileData.highCholesterol ? (
              <IonItem>
                <b>High Cholesterol </b>:{" "}
                {profileData.highCholesterol ? "Yes" : "No"}
              </IonItem>
            ) : null}

            <IonItem>
              <b>Feeling Down</b>: {profileData.WellnessFeelingDown}
            </IonItem>

            <IonItem>
              <b>Activity Satisfaction</b>: {profileData.activitySatisfaction}
            </IonItem>

            <IonItem>
              <b>Diet Satisfaction</b>: {profileData.dietSatisfaction}
            </IonItem>

            <IonItem>
              <b>Sleep Satisfaction</b>: {profileData.sleepSatisfaction}
            </IonItem>

            <IonItem>
              <b>Stress Level Satisfaction</b>:{" "}
              {profileData.stressLevelSatisfaction}
            </IonItem>

            <IonItem>
              <b>Anxiety</b>: {profileData.anxiety ? "Yes" : "No"}
            </IonItem>

            <IonItem>
              <b>Arrhythmia</b>: {profileData.arrhythmia ? "Yes" : "No"}
            </IonItem>

            <IonItem>
              <b>Asthma</b>: {profileData.asthma ? "Yes" : "No"}
            </IonItem>

            <IonItem>
              <b>Backpain</b>: {profileData.backpain ? "Yes" : "No"}
            </IonItem>

            <IonItem>
              <b>Congestive Heart Failure</b>:{" "}
              {profileData.congestiveHeartFailure ? "Yes" : "No"}
            </IonItem>

            <IonItem>
              <b>COPD</b>: {profileData.copd ? "Yes" : "No"}
            </IonItem>

            <IonItem>
              <b>Depression</b>: {profileData.depression ? "Yes" : "No"}
            </IonItem>

            <IonItem>
              <b>Diabetes</b>: {profileData.diabetes}
            </IonItem>

            <IonItem>
              <b>Hypertension</b>: {profileData.hypertension}
            </IonItem>

            <IonItem>
              <b>Blood Pressure Medication</b>: {profileData.bloodPressureMeds}
            </IonItem>

            <IonItem>
              <b>Smoker</b>: {profileData.smoker}
            </IonItem>
          </IonList>
        </>
      </div>
    </>
  );
};

export default BodeeMemberHealthData;
