import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  IonAlert,
  IonButton,
  IonCard,
  IonCardTitle,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import "./style.css";
import { MemberUidContext } from "../../../lib/context/MemberUidContext";
import { fetchGetUser, fetchUpdateUser } from "../../../API/memberAPI";
import { userType } from "../../../Types/user";

export default function PreferredContactMethod() {
  // Get the current member UID from the context
  const memberUid = useContext(MemberUidContext);
  const [currentContactMethod, setCurrentContactMethod] = useState("");
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [userDatabase, setUserDatabase] = useState<userType[]>([]);
  const [placeHolderValue, setPlaceHolderValue] = useState("Select Contact Method")

  // Fetch the member's data
  const fetchGetUserDatabase = useCallback(async () => {
    const userData = await fetchGetUser(memberUid);
    setUserDatabase(userData);
  }, [memberUid]);

  // Use useEffect to call the fetchGetUserDatabase function when the component mounts
  useEffect(() => {
    fetchGetUserDatabase();
  }, [fetchGetUserDatabase]);

  interface Method {
    methodId: number;
    contactMethod: string;
  }

  const methods: Method[] = [
    {
      methodId: 1,
      contactMethod: "SMS",
    },
    {
      methodId: 2,
      contactMethod: "Email",
    },
    {
      methodId: 3,
      contactMethod: "Phone",
    },
    {
      methodId: 4,
      contactMethod: "Direct Message",
    },
  ];

  const handleContactMethodCahange = (e: any) => {
    const selectMethod = e.detail.value.contactMethod;
    setCurrentContactMethod(selectMethod);
    setPlaceHolderValue(selectMethod)
  };

  const handleSetButton = async () => {
    try {
      await fetchUpdateUser(memberUid, {
        preferredContactMethod: currentContactMethod,
      });
      setShowAlert(true);
      console.log("Preferred contact method saved successfully!");
    } catch (error) {
      console.log("Error saving preferred contact method: ", error);
    }
  };

  return (
    <>
      <IonCard>
        <IonCardTitle>Preferred Contact Method</IonCardTitle>
        <IonItem>
          <IonSelect
            placeholder={placeHolderValue}
            onIonChange={handleContactMethodCahange}
            
          >
            {methods.map((preferredMethod) => (
              <IonSelectOption
                key={preferredMethod.methodId}
                value={preferredMethod}
              >
                {preferredMethod.contactMethod}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
        <IonItem>
          <IonLabel>
            Current Method: {userDatabase[0]?.preferredContactMethod ?? "N/A"}
          </IonLabel>
        </IonItem>
        <IonButton onClick={handleSetButton}>SET</IonButton>
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => {
            setShowAlert(false);
            window.location.reload();
          }}
          header="Success"
          message={`UPDATE  PREFERRED CONTRACT METHOD TO <b> ${currentContactMethod} </b> `}
          buttons={[
            {
              text: "OK",
            },
          ]}
        />
      </IonCard>
    </>
  );
}
