import * as react from "@ionic/react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useContext, useState } from "react";
import { UserContext } from "../../lib/context";
import { auth } from "../../lib/firebase";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import "./Login.css";
import { Route, Router } from "workbox-routing";
import { useHistory } from "react-router";

const Login: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { user } = useContext(UserContext);
  const [coach, setCoach] = useState();
  const [resetPasswordEmail, setResetPasswordEmail] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();


  const login = async () => {
    try {
      const user = await signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // return userCredential.user;
          history.push('page/home');


        })
        .catch((error) => {
          setError("Password or email is incorrect");
        });
    } catch (error) {
      const formHelp = document.getElementById("login-help");
      formHelp.innerHTML = "Invalid email or password";
      console.log(error);
    }
  };

  const sendPasswordResetEmailLink = async () => {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        console.log("Password reset email sent!");
        // ..
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        // ..
      });

    setResetPasswordEmail(false);
  };

  const onClick = (e) => {
    e.preventDefault();
    setResetPasswordEmail(true);
  };

  return (
    <react.IonPage>
      <react.IonContent fullscreen>
        <div className="login-centered">
          <react.IonCard className="ion-padding login-card ion-text-center">
            <react.IonCardHeader></react.IonCardHeader>

            <react.IonCardContent>
              <react.IonItem>
                <react.IonLabel position="stacked">Email</react.IonLabel>
                <react.IonInput
                  placeholder="Email"
                  autocomplete="email"
                  onIonChange={(e: any) => {
                    setEmail(e.detail.value);
                  }}
                  name="email"
                ></react.IonInput>
              </react.IonItem>
              <react.IonItem>
                <react.IonLabel position="stacked">Password</react.IonLabel>
                <react.IonInput
                  type="password"
                  placeholder="Password"
                  autocomplete="current-password"
                  onIonChange={(e: any) => {
                    setPassword(e.detail.value);
                  }}
                ></react.IonInput>
              </react.IonItem>

              <p className="error">{error}</p>

              <div className="ion-padding">
                <a
                  onClick={onClick}
                  className="forget-password-link ion-padding"
                >
                  Forget Password?
                </a>
              </div>

              <react.IonButton onClick={login}>Login</react.IonButton>
            </react.IonCardContent>
          </react.IonCard>
        </div>

        {/* <{Toast show={true} />

        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          position="top"
          duration={2000}
          color={toastType}
        />} */}
      </react.IonContent>

      <react.IonModal isOpen={resetPasswordEmail}>
        <react.IonHeader>
          <react.IonToolbar>
            <react.IonButtons slot="start">
              <react.IonButton
                onClick={(e) => {
                  setResetPasswordEmail(false);
                }}
              >
                Cancel
              </react.IonButton>
            </react.IonButtons>
            <react.IonTitle>Reset Password</react.IonTitle>
          </react.IonToolbar>
        </react.IonHeader>
        <react.IonContent>
          <react.IonCard>
            <react.IonCardContent>
              <react.IonItem>
                <react.IonLabel position="stacked">Email</react.IonLabel>
                <react.IonInput
                  type="email"
                  autocomplete="email"
                  value={email}
                  placeholder="Email"
                  onIonChange={(e: any) => {
                    setEmail(e.detail.value);
                  }}
                  name="email"
                ></react.IonInput>
              </react.IonItem>
              <react.IonButton
                className="ion-margin"
                onClick={sendPasswordResetEmailLink}
              >
                Send Password Reset Email
              </react.IonButton>
            </react.IonCardContent>
          </react.IonCard>
        </react.IonContent>
      </react.IonModal>
    </react.IonPage>
  );
};

export default Login;
