import { Route } from "react-router-dom";
import { IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import LoginPage from "./Login";

setupIonicReact();

const PublicRoutes = () => {
  return (
    <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path="/login">
          <LoginPage />
        </Route>
        <Route exact path="/">
          <LoginPage />
        </Route>
        <Route render={() => <LoginPage />} />
      </IonRouterOutlet>
    </IonReactRouter>
  );
};

export default PublicRoutes;
