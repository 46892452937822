import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from "@ionic/react";
import {
  query,
  collection,
  getFirestore,
  getDocs,
  where,
  doc,
  getDoc,
} from "firebase/firestore";
import React from "react";
import { CSVLink } from "react-csv";
import "./ComplianceReport.css";

const BodeeDataReport: React.FC = () => {
  const [csvData, setCsvData] = React.useState([]);

  const resetReport = () => {
    setCsvData([]);
  };

  // remove commas from string
  const removeCommasAndNewlines = (str) => {

    // check if str is a string and if not return str
    if (typeof str !== "string") {
      return str;
    }

    // remove commas and new lines
    var newStr = str.replace(/,/g, " ");
    newStr = newStr.replace(/\n/g, " ");
    return newStr;

  };
  

  const getMembers = async () => {
    var questions = [];
    var quizes = [];

    // get all docs from users
    const q = query(collection(getFirestore(), "koroQuestions"));
    const q2 = query(collection(getFirestore(), "koroQuiz"));

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((document) => {
      var row = document.data();

      if (row.dateCreated) {
        row.dateCreatedSeconds = row.dateCreated.seconds;
        row.dateCreated = row.dateCreated.toDate().toLocaleString() || "N/A";
      }

      if (row.answerDate) {
        row.answerDateSeconds = row.answerDate.seconds;
        row.answerDate = row.answerDate.toDate().toLocaleString() || "N/A";
        
        
      }

      if (row.attemptedDateAnswer) {
        row.attemptedDateAnswerSeconds = row.attemptedDateAnswer.seconds;
        row.attemptedDateAnswer =
          row.attemptedDateAnswer.toDate().toLocaleString() || "N/A";

       

      if(row.claimedDate){
        row.claimedDateSeconds = row.claimedDate.seconds;
        row.claimedDate = row.claimedDate.toDate().toLocaleString() || "N/A";

        // calculate time to claim question and format to hour:minute:second
        var timeToClaim = row.claimedDateSeconds - row.dateCreatedSeconds;
        var hours = Math.floor(timeToClaim / 3600);
        var minutes = Math.floor((timeToClaim - hours * 3600) / 60);
        var seconds = timeToClaim - hours * 3600 - minutes * 60;
        row.timeToClaim = hours + ":" + minutes + ":" + seconds;

         // get time from claim to attempted answer
         var timeToAttemptedAnswer = row.attemptedDateAnswerSeconds - row.claimedDateSeconds;
         var hours = Math.floor(timeToAttemptedAnswer / 3600);
         var minutes = Math.floor((timeToAttemptedAnswer - hours * 3600) / 60);
         var seconds = timeToAttemptedAnswer - hours * 3600 - minutes * 60;
         row.claimToAttemptedAnswer = hours + ":" + minutes + ":" + seconds;
       }

      }

      if(row.responseSubmittedDate){
        row.responseSubmittedDateSeconds = row.responseSubmittedDate.seconds;
        row.responseSubmittedDate = row.responseSubmittedDate.toDate().toLocaleString() || "N/A";

        // calculate from attempted answer to response submitted
        var timeToResponseSubmitted = row.responseSubmittedDateSeconds - row.attemptedDateAnswerSeconds;
        var hours = Math.floor(timeToResponseSubmitted / 3600);
        var minutes = Math.floor((timeToResponseSubmitted - hours * 3600) / 60);
        var seconds = timeToResponseSubmitted - hours * 3600 - minutes * 60;
        row.attemptedAnswerToResponseSubmitted = hours + ":" + minutes + ":" + seconds;
      }

      //total time from question created to response submitted
      if(row.responseSubmittedDate && row.dateCreated){
        var timeToResponseSubmitted = row.responseSubmittedDateSeconds - row.dateCreatedSeconds;
        var hours = Math.floor(timeToResponseSubmitted / 3600);
        var minutes = Math.floor((timeToResponseSubmitted - hours * 3600) / 60);
        var seconds = timeToResponseSubmitted - hours * 3600 - minutes * 60;
        row.totalTimeToResponseSubmitted = hours + ":" + minutes + ":" + seconds;
      }

      questions.push({ id: document.id, ...row });
    });

    const querySnapshot2 = await getDocs(q2);

    querySnapshot2.forEach((document) => {
      var row = document.data();
      quizes.push({ id: document.id, ...row });
    });

    var mergedArray = [];

    // merge the two arrays where id matches and if not matching koroQuiz still add
    questions.forEach((question) => {
      quizes.forEach((quiz) => {
        if (question.id === quiz.id) {
          mergedArray.push({ ...question, ...quiz });
        }
      });
    });

    // find questions that don't have a quiz and add them to the merged array
    questions.forEach((question) => {
      if (!mergedArray.find((row) => row.id === question.id)) {
        mergedArray.push({ ...question });
      }
    });



    // remove timestamp field
    mergedArray.forEach((row) => {
      delete row.timestamp;
    });

    var formatedArray  = [];

    // format the array to be in the correct order
    mergedArray.forEach((row) => {

      if (row.upvotes) {
        row.numberUpvotes = row.upvotes.length;
      } else {
        row.numberUpvotes = 0;
      }

      if (row.downvotes) {
        row.numberDownvotes = row.downvotes.length;
      } else {
        row.numberDownvotes = 0;
      }

      if (row.comments) {
        row.numberComments = row.comments.length;
      } else {
        row.numberComments = 0;
      }

      if (row.replies) {
        row.numberReplies = row.replies.length;
      } else {
        row.numberReplies = 0;
      }


      const entry = {
        id: row.id,
        poster: row.poster,
        dateCreated: row.dateCreated,
        dateCreatedSeconds: row.dateCreatedSeconds,
        title: removeCommasAndNewlines(row.title),
        body: removeCommasAndNewlines(row.body),
        category: row.category,
        upvotes: row.upvotes,
        numberUpvotes: row.numberUpvotes,
        downvotes: row.downvotes,
        numberDownvotes: row.numberDownvotes,
        comments: row.comments,
        numberComments: row.numberComments,
        replies: row.replies,
        numberReplies: row.numberReplies,
        posterGender: row.posterGender,
        status: row.status,
        answer: removeCommasAndNewlines(row.answer),
        answerDate: row.answerDate,
        answerDateSeconds: row.answerDateSeconds,
        answerCoach: row.answerCoach,
        attemptedAnswer: removeCommasAndNewlines(row.attemptedAnswer),
        attemptedAnswerCoach: row.attemptedAnswerCoach,
        attemptedDateAnswer: row.attemptedDateAnswer,
        attemptedDateAnswerSeconds: row.attemptedDateAnswerSeconds,
        claimed: row.claimed,
        claimedBy: row.claimedBy,
        claimedByDisplayName: row.claimedByDisplayName,
        claimedDate: row.claimedDate,
        claimedDateSeconds: row.claimedDateSeconds,
        responseSubmittedDate: row.responseSubmittedDate,
        responseSubmittedDateSeconds: row.responseSubmittedDateSeconds,
        failedAnswers: removeCommasAndNewlines(row.failedAnswers),
        onBehalfOf: row.onBehalfOf,
        emergency: row.emergency,
        chronicIssue: row.chronicIssue,
        bodyPart: row.bodyPart,
        expertAnswerType: row.expertAnswerType,
        recommendFeature: row.recommendFeature,
        timeStamp: row.timeStamp,
        feedback: removeCommasAndNewlines(row.feedback),
        goneInstead: row.goneInstead,
        expert: row.expert,
        useFeatureAgain: row.useFeatureAgain,
        speakToExpert: row.speakToExpert,
        speedRating: row.speedRating,
        whyNotRecommend: removeCommasAndNewlines(row.whyNotRecommend),
        questionSuccessRating: row.questionSuccessRating,
        timeToClaim: row.timeToClaim,
        claimToAttemptedAnswer: row.claimToAttemptedAnswer,
        attemptedAnswerToResponseSubmitted: row.attemptedAnswerToResponseSubmitted,
        totalTimeToResponseSubmitted: row.totalTimeToResponseSubmitted,
      }
      formatedArray.push(entry);
    }
    );


    setCsvData(formatedArray);

  };

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>Bodee Question Report Report</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        {csvData.length === 0 ? (
          <IonButton onClick={getMembers}>Get Report</IonButton>
        ) : (
          <>
            <CSVLink
              data={csvData}
              className="downloadButton"
              filename={"BodeeQuestionDataReport" + Date.now() + ".csv"}
            >
              Download Report Here
            </CSVLink>{" "}
            <br></br>
            <IonButton onClick={resetReport}>New Report</IonButton>
          </>
        )}
      </IonCardContent>
    </IonCard>
  );
};

export default BodeeDataReport;
