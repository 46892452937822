import { useEffect, useState } from "react";
import "./style.css";
import { doc, getDoc, getFirestore, updateDoc } from "@firebase/firestore";
import {
  IonChip,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";

import { GoalsList } from "../../../Interfaces/Goals";
import { InterestList } from "../../../Interfaces/Interest";
import { ClinicalConcernsList } from "../../../Interfaces/ClinicalConcerns";

type Props = {
  question: any;
};

const TagManagement: React.FC<Props> = ({ question }) => {
  const [tagsList, setTagsList] = useState<any[]>(question.tags);
  const [goalTags, setGoalTags] = useState<any[]>([]);
  const [interestTags, setInterestTags] = useState<any[]>([]);
  const [clinicalConcerns, setClinicalConcerns] = useState<any[]>([]);

  const initList = async () => {
    // get list of tags that are also in GoalsList
    // get list of tags that are also in InterestList

    const goalTagsList = [];
    const interestTagsList = [];
    const clinicalConcernsList = [];

    for (let tag of tagsList) {
      const goalTag = GoalsList.find((item) => item.id === tag);
      const interestTag = InterestList.find((item) => item.id === tag);
      const clinicalConcernTag = ClinicalConcernsList.find(
        (item) => item.id === tag
      );

      if (goalTag != undefined) goalTagsList.push(goalTag.id);
      if (interestTag != undefined) interestTagsList.push(interestTag.id);
      if (clinicalConcernTag != undefined)
        clinicalConcernsList.push(clinicalConcernTag.id);
    }

    setGoalTags(goalTagsList);
    setInterestTags(interestTagsList);
    setClinicalConcerns(clinicalConcernsList);

  };

  useEffect(() => {
    initList();
  }, []);

  const RenderInterestTag = ({ tag }) => {
    // find tag in GoalLists.id\

    const tagObj = InterestList.find((item) => item.id === tag);

    if (tagObj == undefined) return null;

    return <IonChip>{tagObj.title}</IonChip>;
  };

  const RenderGoalTag = ({ tag }) => {
    // find tag in GoalLists.id\

    const tagObj = GoalsList.find((item) => item.id === tag);

    if (tagObj == undefined) return null;

    return <IonChip>{tagObj.title}</IonChip>;
  };

  const updateTags = async () => {
    // check if tags list is different from question.tags

    // if it is, update the question.tags in firestore
    const newTagsList = [...goalTags, ...interestTags, ...clinicalConcerns];

    // check if newTagsList is different from question.tags have any differences

    if (JSON.stringify(newTagsList) === JSON.stringify(question.tags)) {
      console.log("no changes");
      return;
    }

    const db = getFirestore();
    const docRef = doc(db, "koroQuestions", question.id);
    updateDoc(docRef, {
      tags: newTagsList,
    });
  };

  return (
    <>
      <div>
        <h2>Tag Management</h2>
        <IonLabel>
          <h2 className="ion-margin-bottom">Active Interest Tags</h2>
        </IonLabel>
        {interestTags.map((tag) => (
          <RenderInterestTag tag={tag} />
        ))}
        <IonItem>
          <IonSelect
            placeholder="Select Interest Tag"
            multiple={true}
            value={interestTags}
            onIonChange={(e) => {
              setInterestTags(e.target.value);
            }}
            onIonDismiss={() => {
              updateTags();
            }}
          >
            {InterestList.map((tag) => (
              <IonSelectOption value={tag.id}>{tag.title}</IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
        <IonLabel>
          <h2 className="ion-margin-bottom">Active Goal Tags</h2>
        </IonLabel>
        {goalTags.map((tag) => (
          <RenderGoalTag tag={tag} />
        ))}
        <IonItem>
          <IonSelect
            placeholder="Select Interest Tag"
            multiple={true}
            value={goalTags}
            onIonChange={(e) => {
              setGoalTags(e.target.value);
            }}
            onIonDismiss={() => {
              updateTags();
            }}
          >
            {GoalsList.map((tag) => (
              <IonSelectOption value={tag.id}>{tag.title}</IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
      </div>
    </>
  );
};

export default TagManagement;
