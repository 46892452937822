import { IonAvatar, IonRouterLink } from "@ionic/react";
import "./index.css";
import { ClinicalConcernsList } from "../../../Interfaces/ClinicalConcerns";
import InterestTile from "../ItemTile";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import required modules
import { useContext } from "react";
import { UserContext } from "../../../lib/context";

type Props = {
  refresh;
  focus;
};

const ClinicalConcernSlider: React.FC<Props> = ({ refresh, focus }) => {

  if (focus == "all") {
    return (
      <div className="on-padding-start ion-margin">
        <Swiper spaceBetween={10}
          slidesPerView={2.5}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}>
          {ClinicalConcernsList.map((clinicalConcern, index) => {
            return (
              <SwiperSlide key={clinicalConcern.title}>
                <InterestTile item={clinicalConcern} refresh={refresh} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    );
  }


  return <></>;

};

export default ClinicalConcernSlider;
