import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import "./index.css";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../lib/context";
import { addOutline, closeOutline } from "ionicons/icons";
import { usePhotoGallery } from "../../../lib/usePhotoGallery";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { InterestList } from "../../../Interfaces/Interest";
import {
  addDoc,
  collection,
  doc,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";

import { Question } from "../../../Interfaces/Question";
import { timeSince } from "../../../lib/Helpers/time";
import HeaderItem from "../QuestionCard/HeaderItem";
import HeaderImgAndTitle from "../QuestionCard/HeaderImgAndTitle";

import Avatar from "avataaars";
import { ClinicalConcernsList } from "../../../Interfaces/ClinicalConcerns";

type Props = {};

const MessageBox: React.FC<Props> = () => {
  const { user, profile } = useContext(UserContext);
  const [askAQuestion, setAskAQuestion] = useState(false);
  const { takePhoto, clearPhoto } = usePhotoGallery();
  const [photo, setPhoto] = useState({}) as any;

  const [koroQuiz, setKoroQuiz] = useState(false);

  const [presentAlert] = useIonAlert();

  const [disclaimer, setDisclaimer] = useState(false);

  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [clinicalConcern, setClinicalConcern] = useState("");

  const [onBehalfOf, setOnBehalfOf] = useState("");
  const [emergency, setEmergency] = useState("");
  const [chronicIssue, setChronicIssue] = useState("");
  const [bodyPart, setBodyPart] = useState("");
  const [expertAnswerType, setExpertAnswerType] = useState("");

  const [video, setVideo] = useState("");
  const [resources, setResources] = useState([]);

  const [currentResource, setCurrentResource] = useState("");

  const [questionRef, setQuestionRef] = useState({}) as any;

  var getFileBlob = function (url, cb) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.addEventListener("load", function () {
      cb(xhr.response);
    });
    xhr.send();
  };

  const addImage = async () => {
    // setPhotoLoading(true);
    takePhoto().then(async (photo) => {
      getFileBlob(photo.webPath, (blob) => {
        setPhoto({ photo, blob });
      });
    });
  };

  const presentDisclaimer = () => {
    presentAlert({
      header: "Disclaimer",
      message:
        "I acknowledge that any advice provided is not diagnostic or designed to treat any health issues",
      buttons: [
        {
          text: "Do Not Agree",
          role: "cancel",
        },
        {
          text: "Agree",
          role: "confirm",
          handler: () => {
            createQuestion();
          },
        },
      ],
    });
  };

  const createQuestion = async () => {
    const tags = [];
    tags.push(clinicalConcern);

    const question = {
      title,
      body,
      clinicalConcern,
      tags,
      photo: null,
      upvotes: [],
      downvotes: [],
      comments: [],
      expert: true,
      hidden: false,
      shareLinks: "",
      poster: user.uid,
      posterDisplayName: profile.firstName + " " + profile.lastName,
      posterAvatar: profile.photoURL,
      posterGender: "Female",
      dateCreated: new Date(),
      failedAnswers: 0,
      attemptedDateAnswer: null,
      attemptedAnswer: "",
      attemptedAnswerCoach: "",
      answerDate: null,
      answer: "",
      answerCoach: "",
      status: "waitingForAnswer",
      timeStamp: null,
      video,
      resources,
    };

    if (photo.photo) {
      const storage = getStorage();
      const fileName = user.uid + "_" + new Date().getTime() + ".jpeg";
      const profilePhotoRef = ref(storage, "questionCoverImage/" + fileName);
      console.log("profilePhotoRef", profilePhotoRef);
      uploadBytes(profilePhotoRef, photo.blob).then(() => {
        getDownloadURL(profilePhotoRef).then(async (url) => {
          question.photo = url;

          const docRef = collection(getFirestore(), "koroQuestions");

          addDoc(docRef, question)
            .then((document) => {
              console.log("Document successfully written 456!", document);
              setQuestionRef(document);
              setTitle("");
              setBody("");
              setClinicalConcern("");
              setPhoto({});
              clearPhoto();
              presentAlert({
                header: "Thank you!",
                message:
                  "Your question has been submitted. We will notify you when we have an answer for you! In the meantime, please complete your Bodee Quiz.",
                buttons: [
                  {
                    text: "Okay",
                    handler: () => {
                      setAskAQuestion(false);
                      setKoroQuiz(true);
                    },
                  },
                ],
              });
            })
            .catch((error) => {
              console.error("Error writing document: ", error);
            });
        });
      });
    } else {
      const docRef = collection(getFirestore(), "koroQuestions");

      addDoc(docRef, question)
        .then((document) => {
          console.log("Document successfully written 123!@@", document);
          setTitle("");
          setBody("");
          setQuestionRef(document);
          setClinicalConcern("");
          setPhoto({});
          clearPhoto();
          try {
            console.log("TRYING");
            setTimeout(() => {
              console.log("TIMEOUT");
              presentAlert({
                header: "Thank you!",
                message:
                  "Your question has been submitted. We will notify you when we have an answer for you! In the meantime, please complete your Bodee Quiz.",
                buttons: [
                  {
                    text: "Okay",
                    handler: () => {
                      setKoroQuiz(true);
                      setAskAQuestion(false);
                    },
                  },
                ],
              });
            }, 500);
          } catch (error) {
            console.log(error);
          }
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    }
  };

  const submitKoroQuiz = () => {
    console.log("Submit Koro Quiz", questionRef);

    const koroQuiz = {
      onBehalfOf,
      emergency,
      chronicIssue,
      bodyPart,
      expertAnswerType,
      uid: user.uid,
      timestamp: new Date(),
      questionId: questionRef.id,
    };

    // add to collect koroQuiz in firestore with id matching question id
    setDoc(doc(getFirestore(), "koroQuiz", questionRef.id), koroQuiz)
      .then(() => {
        console.log("Document successfully written!");
        setKoroQuiz(false);
        setOnBehalfOf("");
        setEmergency("");
        setChronicIssue("");
        setBodyPart("");
        setExpertAnswerType("");
        setQuestionRef({});
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });

    // console.log(koroQuiz);
  };

  return (
    <>
      <IonItem
        className="koroMessageBoxItem ion-margin"
        lines="none"
        onClick={() => setAskAQuestion(true)}
      >
        {/* <IonAvatar slot="start" className="ion-margin-top ion-margin-bottom">
          <img src={koroPublicAvatar?.avatar} />
        </IonAvatar> */}
        <div className="ion-margin-end">
          {/* <Avatar
            style={{ width: "40px", height: "40px" }}
            avatarStyle="Circle"
            {...koroPublicAvatar.avatarConfig}
          /> */}
        </div>

        <p className="ion-text-wrap whatToShare">Add coach post here...</p>
      </IonItem>
      <IonModal
        isOpen={askAQuestion}
        onDidDismiss={() => setAskAQuestion(false)}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons>
              <IonButton onClick={() => setAskAQuestion(false)} slot="start">
                <IonIcon icon={closeOutline} size="large" />
              </IonButton>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton
                disabled={title === "" || clinicalConcern == ""}
                onClick={presentDisclaimer}
              >
                <IonIcon icon={addOutline} /> Post
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonItem lines="none">
            <IonTextarea
              placeholder="Question"
              autofocus
              autoGrow={true}
              value={title}
              autoCapitalize="on"
              autoCorrect="on"
              onIonInput={(e: any) => setTitle(e.target.value)}
              className="koroAskQuestionTitle ion-no-padding ion-no-margin"
            >
              {" "}
            </IonTextarea>
          </IonItem>
          <IonItem lines="none">
            <IonTextarea
              placeholder="Provide more details (optional)"
              className="koroAskQuestionBody ion-no-padding ion-no-margin"
              value={body}
              autoGrow={true}
              autoCapitalize="on"
              autoCorrect="on"
              onIonInput={(e: any) => setBody(e.target.value)}
            ></IonTextarea>
          </IonItem>
          <div className="inputItemForm ion-margin select">
            <IonSelect
              onIonChange={(e: any) => setClinicalConcern(e.target.value)}
              placeholder="Clinical Concern"
              value={clinicalConcern}
              className="ion-no-padding ionSelect"
            >
              {ClinicalConcernsList.map((clinicalConcernObj, index) => {
                return (
                  <IonSelectOption
                    value={clinicalConcernObj.id}
                    key={clinicalConcernObj.id}
                  >
                    {clinicalConcernObj.title}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          </div>

          {photo.photo ? (
            <>
              <div className="koroQuestionHeaderImgWrapper">
                <img
                  className="koroQuestionHeaderImg"
                  src={photo.photo.webPath}
                />
              </div>
              <p
                onClick={() => setPhoto({})}
                className="ion-margin koroAddImageLink ion-text-center"
              >
                Remove
              </p>
            </>
          ) : (
            <p
              className="ion-margin ion-padding koroAddImageLink ion-text-center"
              onClick={addImage}
            >
              Add image
            </p>
          )}

          <IonItem lines="none">
            <IonTextarea
              placeholder="Add video link (optional)"
              className="koroAskQuestionBody ion-no-padding ion-no-margin"
              value={video}
              autoGrow={true}
              autoCapitalize="on"
              autoCorrect="on"
              onIonInput={(e: any) => setVideo(e.target.value)}
            ></IonTextarea>
          </IonItem>

          <IonItem lines="none">
            <IonTextarea
              placeholder="Add resources (optional)"
              className="koroAskQuestionBody ion-no-padding ion-no-margin"
              value={currentResource}
              autoGrow={true}
              autoCapitalize="on"
              autoCorrect="on"
              onIonInput={(e: any) => setCurrentResource(e.target.value)}
            ></IonTextarea>
            <IonButton
              onClick={() => {
                setResources([...resources, currentResource]);
                setCurrentResource("");
              }}
            >
              Add
            </IonButton>
          </IonItem>
          {resources.map((resource, index) => {
            return (
              <IonItem key={index}>
                <IonLabel>{resource}</IonLabel>
                <IonIcon
                  icon={closeOutline}
                  onClick={() => {
                    const newResources = resources.filter(
                      (resource, i) => i !== index
                    );
                    setResources(newResources);
                  }}
                />

              </IonItem>
            );
          })}
        </IonContent>
      </IonModal>
      <IonModal isOpen={koroQuiz}>
        <IonHeader>
          <IonToolbar>
            <IonButtons>
              <IonButton onClick={() => setKoroQuiz(false)} slot="start">
                Skip
              </IonButton>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton
                disabled={
                  onBehalfOf == "" ||
                  emergency == "" ||
                  chronicIssue == "" ||
                  bodyPart == "" ||
                  expertAnswerType == ""
                }
                onClick={submitKoroQuiz}
              >
                Submit
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          {/* xx{questionRef.id}gg */}
          <IonLabel>Who are you asking the question for?</IonLabel>
          <div className="inputItemForm select">
            <IonSelect
              placeholder="I am asking for..."
              className="ion-no-padding ionSelect"
              onIonChange={(e: any) => setOnBehalfOf(e.target.value)}
            >
              <IonSelectOption value="Myself">Myself</IonSelectOption>
              <IonSelectOption value="Spouse">Spouse</IonSelectOption>
              <IonSelectOption value="Child">Child</IonSelectOption>
              <IonSelectOption value="Parent">Parent</IonSelectOption>
              <IonSelectOption value="Friend">Friend</IonSelectOption>
            </IonSelect>
          </div>
          {onBehalfOf !== "" && (
            <>
              <IonLabel>Is this an emergency?</IonLabel>
              <div className="inputItemForm select">
                <IonSelect
                  className="ion-no-padding ionSelect"
                  onIonChange={(e: any) => setEmergency(e.target.value)}
                >
                  <IonSelectOption value="Yes">Yes</IonSelectOption>
                  <IonSelectOption value="No">No</IonSelectOption>
                </IonSelect>
              </div>
            </>
          )}
          {emergency === "" || emergency === "Yes" ? (
            <>
              {emergency === "Yes" && (
                <IonLabel>
                  <p className="ion-text-center">
                    Please contact <a>911</a> if this is an emergency
                  </p>
                </IonLabel>
              )}
            </>
          ) : (
            <>
              <IonLabel>Is this an chronic issue?</IonLabel>
              <div className="inputItemForm select">
                <IonSelect
                  className="ion-no-padding ionSelect"
                  onIonChange={(e: any) => setChronicIssue(e.target.value)}
                >
                  <IonSelectOption value="Yes">Yes</IonSelectOption>
                  <IonSelectOption value="No">No</IonSelectOption>
                </IonSelect>
              </div>
              <IonLabel>What part of your body is being affected?</IonLabel>
              <div className="inputItemForm select">
                <IonSelect
                  className="ion-no-padding ionSelect"
                  onIonChange={(e: any) => setBodyPart(e.target.value)}
                  multiple
                >
                  <IonSelect>
                    <IonSelectOption value="Head (Non-Psychiatric)">
                      Head (Non-Psychiatric)
                    </IonSelectOption>
                    <IonSelectOption value="Ears, Nose, Throat (ENT)">
                      Ears, Nose, Throat (ENT)
                    </IonSelectOption>
                    <IonSelectOption value="Neck">Neck</IonSelectOption>
                    <IonSelectOption value="Shoulder">Shoulder</IonSelectOption>
                    <IonSelectOption value="Elbow">Elbow</IonSelectOption>
                    <IonSelectOption value="Wrist">Wrist</IonSelectOption>
                    <IonSelectOption value="Back">Back</IonSelectOption>
                    <IonSelectOption value="Reproductive system">
                      Reproductive system
                    </IonSelectOption>
                    <IonSelectOption value="Urinary">Urinary</IonSelectOption>
                    <IonSelectOption value="Respiratory">
                      Respiratory
                    </IonSelectOption>
                    <IonSelectOption value="Skin / Dermatological">
                      Skin / Dermatological
                    </IonSelectOption>
                    <IonSelectOption value="Stomach / Intestinal / Colon / Digestive Health">
                      Stomach / Intestinal / Colon / Digestive Health
                    </IonSelectOption>
                    <IonSelectOption value="Eyes / Eye health">
                      Eyes / Eye health
                    </IonSelectOption>
                    <IonSelectOption value="Upper extremities ( arms, hands, fingers)">
                      Upper extremities ( arms, hands, fingers)
                    </IonSelectOption>
                    <IonSelectOption value="Lower extremities (feet, legs, knees)">
                      Lower extremities (feet, legs, knees)
                    </IonSelectOption>
                    <IonSelectOption value="Psychiatric / Stress / Depression / Anxiety / Anger">
                      Psychiatric / Stress / Depression / Anxiety / Anger
                    </IonSelectOption>
                    <IonSelectOption value="Full Body Issues">
                      Full Body Issues
                    </IonSelectOption>
                    <IonSelectOption value="General Pain">
                      General Pain
                    </IonSelectOption>
                    <IonSelectOption value="Heart">Heart</IonSelectOption>
                    <IonSelectOption value="Lungs">Lungs</IonSelectOption>
                    <IonSelectOption value="Kidneys">Kidneys</IonSelectOption>
                    <IonSelectOption value="Endocrine / Blood Sugar">
                      Endocrine / Blood Sugar
                    </IonSelectOption>
                    <IonSelectOption value="Weight / Appearance / Fitness">
                      Weight / Appearance / Fitness
                    </IonSelectOption>
                    <IonSelectOption value="Sleep Issues">
                      Sleep Issues
                    </IonSelectOption>
                    <IonSelectOption value="Bone and Joint Pain">
                      Bone and Joint Pain
                    </IonSelectOption>
                    <IonSelectOption value="General and Specific Muscle Weakness">
                      General and Specific Muscle Weakness
                    </IonSelectOption>
                  </IonSelect>
                </IonSelect>
              </div>
              <IonLabel>
                What is the type of expert answer you’re looking for?
              </IonLabel>

              <div className="inputItemForm select">
                <IonSelect
                  className="ion-no-padding ionSelect"
                  onIonChange={(e: any) => setExpertAnswerType(e.target.value)}
                  multiple
                >
                  <IonSelectOption value="Traditional Medicine">
                    Traditional Medicine
                  </IonSelectOption>
                  <IonSelectOption value="Homeopathic Medicine">
                    Homeopathic Medicine
                  </IonSelectOption>
                  <IonSelectOption value="Functional Medicine">
                    Functional Medicine
                  </IonSelectOption>
                  <IonSelectOption value="Pre-Surgical">
                    Pre-Surgical
                  </IonSelectOption>
                  <IonSelectOption value="Surgical">Surgical</IonSelectOption>
                  <IonSelectOption value="Post-Surgical">
                    Post-Surgical
                  </IonSelectOption>
                  <IonSelectOption value="Diet and Nutrition">
                    Diet and Nutrition
                  </IonSelectOption>
                  <IonSelectOption value="Fitness">Fitness</IonSelectOption>
                  <IonSelectOption value="Supplementation">
                    Supplementation
                  </IonSelectOption>
                  <IonSelectOption value="All of the above">
                    All of the above
                  </IonSelectOption>
                </IonSelect>
              </div>
            </>
          )}
          <IonButton
            shape="round"
            expand="full"
            className="ion-margin"
            onClick={submitKoroQuiz}
            disabled={
              onBehalfOf == "" ||
              emergency == "" ||
              chronicIssue == "" ||
              bodyPart == "" ||
              expertAnswerType == ""
            }
          >
            Submit
          </IonButton>
        </IonContent>
      </IonModal>
    </>
  );
};

export default MessageBox;
