import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  IonButton,
  IonCard,
  IonCardTitle,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonTextarea,
} from "@ionic/react";
import { fetchAddCoachNote } from "../../../API/CoachAPI";
import { CoachNoteType } from "../../../Types/coachNotes";
import { Timestamp } from "@firebase/firestore";
import { MemberUidContext } from "../../../lib/context/MemberUidContext";
import { UserContext } from "../../../lib/context";
import { userType } from "../../../Types/user";
import { fetchGetUser, fetchUpdateUser } from "../../../API/memberAPI";
import CompletedCompliantCard from "./CompletedCompliantCard";
import timeSince from "../../../lib/Helpers/time";

export default function ComplianceModalCoachNote() {
  const [complianceCategory, setComplianceCategory] = useState([]);
  const [coachNote, setCoachNote] = useState("");
  const memberUid = useContext(MemberUidContext);
  const { user, profile } = useContext(UserContext);
  const [userDatabase, setUserDatabase] = useState<userType[]>([]);

  // Fetch the member's data
  const fetchGetUserDatabase = useCallback(async () => {
    const userData = await fetchGetUser(memberUid);
    setUserDatabase(userData);
  }, [memberUid]);

  useEffect(() => {
    fetchGetUserDatabase();
  }, [fetchGetUserDatabase]);

  const [coachNoteData, setCoachNoteData] = useState<Partial<CoachNoteType>>({
    category: [],
    coachEmail: profile.email,
    coachName: `${profile.firstName} ${profile.lastName}`,
    coachUid: profile.uid,
    date: Timestamp.now(),
    email: "",
    name: "",
    note: "",
    uid: memberUid,
    complianceNoteSet: true,
  });

  useEffect(() => {
    setCoachNoteData((prevState) => ({
      ...prevState,
      email: userDatabase?.[0]?.email || "N/A",
      name: `${userDatabase?.[0]?.firstName || "N/A"} ${
        userDatabase?.[0]?.lastName || "N/A"
      }`,
    }));
  }, [userDatabase]);

  useEffect(() => {
    setCoachNoteData((prevState) => ({
      ...prevState,
      category: complianceCategory,
    }));
  }, [complianceCategory]);

  useEffect(() => {
    setCoachNoteData((prevState) => ({ ...prevState, note: coachNote }));
  }, [coachNote]);

  const handleTestUpdateDoc = async () => {
    console.log("cata", complianceCategory);
    try {
      await fetchAddCoachNote(coachNoteData);
      console.log("Document added successfully!");

      await fetchUpdateUser(memberUid, { compliant: true, compliantSetDate: Timestamp.now(), markedUncompliantDate: null});
      
      window.location.reload()
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const categories = [
    { value: "Demographics", label: "Demographics" },
    { value: "Medical Hx", label: "Medical Hx" },
    { value: "Medications/Supplements", label: "Medications/Supplements" },
    { value: "Biometrics", label: "Biometrics" },
    { value: "Activity", label: "Activity" },
    { value: "Goal Update", label: "Goal Update" },
    { value: "Personal Life", label: "Personal Life" },
    { value: "Other", label: "Other" },
  ];

  // Sort the options array by their value in alphabetical order
  categories.sort((a, b) => a.value.localeCompare(b.value));

  const timeSince = (date) => {
    console.log("date", date)

    if (date === null || date === undefined) {
      return "0";
    }

    const today = new Date();
    const date2 = new Date(date.toDate());

    const diffTime = Math.abs(today.getTime() - date2.getTime());

    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;

    
  };


  return (
    <>
      {userDatabase.map((userDB) =>
        userDB.compliant ? (
          <>
          <CompletedCompliantCard/>
          </>
        ) : (
          <IonCard>
            <IonCardTitle>Compliance Check</IonCardTitle>
            <IonLabel>
              <p>Uncompliant for {timeSince(userDB["markedUncompliantDate"])} days</p>
            </IonLabel>
            

            <IonItem>
              <IonSelect
                multiple
                placeholder="Select Compliance Catagories"
                value={complianceCategory}
                onIonChange={(e) => {
                  setComplianceCategory(e.detail.value);
                }}
              >
                {categories.map((option) => (
                  <IonSelectOption key={option.value} value={option.value}>
                    {option.label}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Note</IonLabel>
              <IonTextarea
                name="note"
                required={true}
                autoGrow={true}
                value={coachNote}
                onIonChange={(e) => {
                  setCoachNote(e.detail.value);
                }}
              ></IonTextarea>
            </IonItem>
            <IonButton type="submit" onClick={handleTestUpdateDoc}>
              Set Monthly Compliance
            </IonButton>
          </IonCard>
        )
      )}
    </>
  );
}
