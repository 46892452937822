//import spinner
import { IonCol, IonGrid, IonLabel, IonRow } from "@ionic/react";
import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const GoalsDailyGraph = ({ goalData }) => {
  const getPercentageComplete = (goal) => {
    const startDate = new Date(goal.startDate.seconds * 1000);
    const endDate = new Date(goal.endDate.seconds * 1000);
    const today = new Date();

    //get number of days between start and end date
    const daysBetween = Math.round(
      Math.abs(startDate.getTime() - endDate.getTime())
    );
    const daysCompleted = Math.round(
      Math.abs(startDate.getTime() - today.getTime())
    );

    let TotalDays = Math.ceil(daysBetween / (1000 * 3600 * 24)) + 1;
    let CompletedDays = Math.ceil(daysCompleted / (1000 * 3600 * 24));

    return Math.round((CompletedDays / TotalDays) * 100);
  };

  const getPercentSuccess = (goal) => {
    const startDate = new Date(goal.startDate.seconds * 1000);
    const today = new Date();

    //get number of days between start and end date
    const daysCompleted = Math.round(
      Math.abs(startDate.getTime() - today.getTime())
    );

    let CompletedDays = Math.ceil(daysCompleted / (1000 * 3600 * 24));

    return Math.round((goal.numberCompletes / CompletedDays) * 100);
  };

  return (
    <>
    {goalData.hasEndDate ? (<><IonGrid>
        <IonRow>
          <IonCol>
            {" "}
            <IonLabel color="primary" className="ion-padding">
              {" "}
              Completion{" "}
            </IonLabel>
            <div
              style={{ width: 100, height: 100 }}
              className="progress-circle"
            >
              <CircularProgressbar
                value={getPercentageComplete(goalData)}
                text={`${getPercentageComplete(goalData)}%`}
                styles={buildStyles({
                  // Rotation of path and trail, in number of turns (0-1)
                  rotation: 0.25,

                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: "butt",

                  // Text size
                  textSize: "16px",

                  // How long animation takes to go from one percentage to another, in seconds
                  pathTransitionDuration: 0.5,

                  // Can specify path transition in more detail, or remove it entirely
                  // pathTransition: 'none',

                  // Colors
                  textColor: "#456673",
                  trailColor: "#d6d6d6",
                  backgroundColor: "#456673",
                  pathColor: "#456673",
                })}
              />
            </div>
          </IonCol>
          <IonCol>
            <IonLabel color="success" className="ion-padding">
              {" "}
              Success{" "}
            </IonLabel>

            <div
              style={{ width: 100, height: 100 }}
              className="progress-circle"
            >
              <CircularProgressbar
                value={getPercentSuccess(goalData)}
                text={`${getPercentSuccess(goalData)}%`}
                styles={buildStyles({
                  // Rotation of path and trail, in number of turns (0-1)
                  rotation: 0.25,

                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: "butt",

                  // Text size
                  textSize: "16px",

                  // How long animation takes to go from one percentage to another, in seconds
                  pathTransitionDuration: 0.5,

                  // Can specify path transition in more detail, or remove it entirely
                  // pathTransition: 'none',

                  // Colors
                  textColor: "#07dc1d",
                  trailColor: "#d6d6d6",
                  backgroundColor: "#456673",
                  pathColor: "#07dc1d",
                })}
              />
            </div>
          </IonCol>
        </IonRow>
      </IonGrid></>) : (<><IonGrid>
        <IonRow>
          <IonCol>
            <IonLabel color="success" className="ion-padding">
              {" "}
              Success{" "}
            </IonLabel>

            <div
              style={{ width: 100, height: 100 }}
              className="progress-circle"
            >
              <CircularProgressbar
                value={getPercentSuccess(goalData)}
                text={`${getPercentSuccess(goalData)}%`}
                styles={buildStyles({
                  // Rotation of path and trail, in number of turns (0-1)
                  rotation: 0.25,

                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: "butt",

                  // Text size
                  textSize: "16px",

                  // How long animation takes to go from one percentage to another, in seconds
                  pathTransitionDuration: 0.5,

                  // Can specify path transition in more detail, or remove it entirely
                  // pathTransition: 'none',

                  // Colors
                  textColor: "#07dc1d",
                  trailColor: "#d6d6d6",
                  backgroundColor: "#456673",
                  pathColor: "#07dc1d",
                })}
              />
            </div>
          </IonCol>
        </IonRow>
      </IonGrid></>)}
      

    

      <br></br>
    </>
  );
};

export default GoalsDailyGraph;
