import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../lib/context";
import QuestionCard from "../QuestionCard";
import "./style.css";
import { AlertInput, IonAlert, IonLabel, useIonAlert } from "@ionic/react";
import { InterestList } from "../../../Interfaces/Interest";
import { doc, getFirestore, updateDoc } from "@firebase/firestore";
import { ClinicalConcernsList } from "../../../Interfaces/ClinicalConcerns";

type Props = {
  questions: any;
};

const CoachFocusSection: React.FC<Props> = ({ questions }) => {
  const { user, profile } = useContext(UserContext);

  const [presentAlert] = useIonAlert();

  const ListOfCLinicalConcerns: AlertInput[] = [];

  ClinicalConcernsList.map((interest, index) => {
    ListOfCLinicalConcerns.push({
      label: interest.title,
      type: "checkbox",
      value: interest.id,
      checked: profile.clinicalConcerns?.includes(interest.id),
    });
  });

  const updateInterest = async (interest: string[]) => {
    console.log(interest);
    // update doc firebase
    const userDoc = doc(getFirestore(), "users", user.uid);

    // Set the "capital" field of the city 'DC'
    await updateDoc(userDoc, {
      clinicalConcerns: interest,
    });
  };

  useEffect(() => {
    if (!profile.clinicalConcerns) {
      presentAlert({
        header: "Select Your Interest",
        buttons: [
          { text: "Cancel" },
          {
            text: "Ok",
            handler: (d) => {
              updateInterest(d);
            },
          },
        ],
        inputs: ListOfCLinicalConcerns,
      });
    }
  }, []);

  // check to see if any questions tags array matches any of the profile.clinicalConcerns
  if (profile.clinicalConcerns) {
    const questionsOfInterest = questions.filter((question) => {
      return (
        question.tags &&
        question.tags.some((r) => profile.clinicalConcerns?.includes(r))
      );
    });
    console.log(questionsOfInterest);

    if (questionsOfInterest.length == 0) {
      return (
        <div className="ion-margin-top">
          <h3 className="ion-margin">Questions For You</h3>
          <IonLabel>
            <p>There are no questions for you at this time.</p>
          </IonLabel>
        </div>
      );
    }
  }

  return (
    <>
    </>
  );
};

export default CoachFocusSection;
