import {
  IonAccordion,
  IonAccordionGroup,
  IonItem,
  IonLabel,
} from "@ionic/react";
import "./style.css";
import HeaderItem from "./HeaderItem";
import HeaderImgAndTitle from "./HeaderImgAndTitle";
import Toolbar from "./Toolbar";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../lib/context";

import { timeSince } from "../../../lib/Helpers/time";
import { getUserAvatar } from "../../../lib/helper";
import KoroQuiz from "../KoroQuiz";
import PostQuestionSurvey from "../PostQuestionSurvey";
import BodeeMemberHealthData from "../BodeeMemberHealthData";
import TagManagement from "../TagManagement";

type Props = { question; refresh; refreshHandler };

const QuestionCard: React.FC<Props> = ({
  question,
  refresh,
  refreshHandler,
}) => {
  const { profile } = useContext(UserContext);
  const [avatarUrl, setAvatarUrl] = useState("");

  const [questionData, setQuestionData] = useState(question);

  const fetchAvatar = async () => {
    const data = await getUserAvatar(questionData?.poster);
    // console.log(data["avatar"])
    // console.log("date", data);
    if (data == undefined) {
      setAvatarUrl(questionData.posterAvatar);
    }
    if (data == undefined) return;

    setAvatarUrl(data["avatar"]);
  };

  useEffect(() => {
    fetchAvatar();
  }, [profile]);

  useEffect(() => {
    fetchAvatar();
  }, [refresh]);

  const questionHandler = (question) => {
    console.log("Root handeler", question)
    setQuestionData(question);
  };


  return (
    <>
      <div className="koroQuestionCard ion-margin">
        <HeaderItem
          avatar={avatarUrl}
          name={questionData.posterDisplayName}
          category={questionData.category}
          publicAvatarUid={questionData.poster}
          questionId={questionData.id}
          isHidden={questionData.hidden}
          question={questionData}
          refreshHandler={refreshHandler}
          time={timeSince(questionData.dateCreated)}
        />
        <HeaderImgAndTitle
          img={questionData.photo}
          title={questionData.title}
          body={questionData.body}
        />
        <Toolbar
          downvotes={questionData.downvotes}
          upvotes={questionData.upvotes}
          share="3"
          id={questionData.id}
          comments={4}
          questionHandler={questionHandler}
          question={questionData}
        />
        {questionData.status == "answered" && (
          <>
            <IonLabel color="primary" className="ion-margin">
              <h2 className="bold">Answer: </h2>
            </IonLabel>
            <IonLabel className="ion-padding">
              <p className="ion-padding-start">{questionData.answer}</p>
            </IonLabel>
          </>
        )}
        <IonAccordionGroup>
          <IonAccordion value="first">
            <IonItem slot="header" color="light">
              <IonLabel>Bodee Quiz</IonLabel>
            </IonItem>
            <div className="ion-padding" slot="content">
              <KoroQuiz id={questionData.id} />
            </div>
          </IonAccordion>
          <IonAccordion value="second">
            <IonItem slot="header" color="light">
              <IonLabel>Member Health Data</IonLabel>
            </IonItem>
            <div className="ion-padding" slot="content">
              <BodeeMemberHealthData uid={questionData.poster} />
            </div>
          </IonAccordion>
          <IonAccordion value="third">
            <IonItem slot="header" color="light">
              <IonLabel>Post Question Survey</IonLabel>
            </IonItem>
            <div className="ion-padding" slot="content">
              <PostQuestionSurvey question={questionData} />
            </div>
          </IonAccordion>
          <IonAccordion value="fourth">
            <IonItem slot="header" color="light">
              <IonLabel>Tag Management</IonLabel>
            </IonItem>
            <div className="ion-padding" slot="content">
              <TagManagement question={questionData} />
            </div>
          </IonAccordion>
        </IonAccordionGroup>
      </div>
    </>
  );
};

export default QuestionCard;
