import { IonItem, IonLabel, IonModal, IonPopover } from "@ionic/react";
import RenderExistingMembers from "../RenderExistingMembers";
import "./style.css";
import RenderNewMembers from "../RenderNewMembers";
import {
  collection,
  getDoc,
  getDocs,
  getFirestore,
  query,
  where,
} from "@firebase/firestore";
import React from "react";
import { error } from "console";

type Props = {
  file: any;
};

const RenderEligibilityFiles: React.FC<Props> = ({ file }) => {
  const RenderErrors = ({ batchId }: any) => {
    const [errors, setErrors] = React.useState<any>([]);
    const [showErrors, setShowErrors] = React.useState(false);

    const fetchErrors = async () => {
      const q = query(
        collection(getFirestore(), "eligibility_file_errors"),
        where("batchId", "==", batchId)
      );

      const querySnapshot = await getDocs(q);

      const errorsList: any = [];

      querySnapshot.forEach((doc) => {
        errorsList.push(doc.data());
      });

      setErrors(errorsList);
    };

    React.useEffect(() => {
      fetchErrors();
    }, []);

    if (errors.length === 0) {
      return <>No Errors</>;
    }

    return (
      <>
        <a onClick={() => setShowErrors(!showErrors)}>{errors.length}</a>
        <IonModal isOpen={showErrors} onDidDismiss={() => setShowErrors(false)}>
          <IonItem>
            <IonLabel className="ion-text-wrap">Error</IonLabel>
          </IonItem>
          {errors.map((error: any) => (
            <IonItem key={error.id}>
              <IonLabel className="ion-text-wrap">{error.error}</IonLabel>
            </IonItem>
          ))}
        </IonModal>
      </>
    );
  };

  return (
    <>
      <IonItem key={file.id}>
        <IonLabel className="ion-text-wrap">{file.groupName}</IonLabel>
        <IonLabel className="ion-text-wrap">{file.requester}</IonLabel>
        <IonLabel className="ion-text-wrap">
          {new Date(file.requestedDate.seconds * 1000).toLocaleDateString()}{" "}
          {new Date(file.requestedDate.seconds * 1000).toLocaleTimeString()}
        </IonLabel>
        <IonLabel className="ion-text-wrap">{file.totalMembers}</IonLabel>
        <IonLabel className="ion-text-wrap">
          {/* <RenderNewMembers id={file.id} /> */}
          {file.totalMembers - file.existingMembers}
        </IonLabel>
        <IonLabel className="ion-text-wrap">{file.existingMembers}</IonLabel>
        <IonLabel className="ion-text-wrap">
          <RenderErrors batchId={file.id} />
        </IonLabel>
      </IonItem>
    </>
  );
};

export default RenderEligibilityFiles;
