import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonDatetime,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonPopover,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import {
  query,
  collection,
  getFirestore,
  where,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
  addDoc,
  orderBy,
} from "firebase/firestore";
import { add, checkbox, chevronForward, squareOutline } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import GoalsDailyGraph from "./GoalsDailyGraph";
import GoalsOneTimeGraph from "./GoalsOneTimeGraph";
import GoalsWeeklyGraph from "./GoalsWeeklyGraph";
import './CoachNotes.css';

import "./GoalsCard.css";
import { getCurrentDate } from "../../HelperFunctions/dates";
import { UserContext } from "../../lib/context";

export default function NotesCards({
  uid,
  email,
  name,
  coachUid,
  coachEmail,
  coachName,
}) {
  const [notes, setNotes] = useState([]);
  const [addNoteOpen, setAddNoteOpen] = useState(false);
  const [noteText, setNoteText] = useState("");
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState([]);


  const RenderCategory = ({category}) => {
    return <>{category.toString()}</>
  }

  const Note = ({ note }) => {

    const [editNoteOpen, setEditNoteOpen] = useState(false);

          
      const editCoachNote = async (e: { preventDefault: () => void; target }) => {
        e.preventDefault();
        const noteRef = doc(getFirestore(), "coach_notes", note.id);
        const updateNote = {
          note: note.note,
          category: note.category,
        };
        await updateDoc(noteRef, updateNote);
        setEditNoteOpen(false);
        fetchNotes();
      };


    return (
      <IonCard>
      <IonCardHeader>
        <IonCardTitle><RenderCategory category={note.category} /></IonCardTitle>
        <IonCardSubtitle>
          <p>{new Date(note.date.seconds * 1000).toDateString()}</p>
          <p>
            Posted by: {note.coachName}
          </p>
        </IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        <p>{note.note}</p>
      </IonCardContent>
      <a className="delete-link" onClick={() => deleteCoachNote(note.id)}>Delete</a>
      <br></br>
      <a className="delete-link" onClick={() => setEditNoteOpen(true)}>Edit</a>
      <IonModal isOpen={editNoteOpen} onDidDismiss={() => {setEditNoteOpen(false)}}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Edit Note</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setEditNoteOpen(false)}>
                Cancel
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <form onSubmit={editCoachNote}>
      <IonItem>
        <IonLabel position="floating">Catagories</IonLabel>
        <IonSelect
          multiple={true}
          value={note.category}
          onIonChange={(e) => note.category = e.detail.value}
        >
          <IonSelectOption value="Demographics">Demographics</IonSelectOption>
          <IonSelectOption value="Medical Hx">Medical Hx</IonSelectOption>
          <IonSelectOption value="Medications/Supplements">Medications/Supplements</IonSelectOption>
          <IonSelectOption value="Biometrics">Biometrics</IonSelectOption>
          <IonSelectOption value="Activity">Activity</IonSelectOption>
          <IonSelectOption value="Goal Update">Goal Update</IonSelectOption>
          <IonSelectOption value="Personal Life">Personal Life</IonSelectOption>
          <IonSelectOption value="Other">Other</IonSelectOption>
        </IonSelect>
      </IonItem>
      <IonItem>
        <IonLabel position="floating">Note</IonLabel>
        <IonTextarea
          value={note.note}
          autoGrow={true}
          onIonChange={(e) => note.note = e.detail.value}
        ></IonTextarea>
      </IonItem>
      <IonButton type="submit">Submit</IonButton>
    </form>
      </IonModal>
    </IonCard>
    )



  };

  const RenderNotes = () => {



    let notesList = [];
    notes.forEach((note) => {
      


      notesList.push(
        <Note note={note} />
      );
    });

    return <>{notesList}</>;
  };

  const fetchNotes = async () => {
    const q = query(
      collection(getFirestore(), "coach_notes"),
      where("uid", "==", uid),
      orderBy("date", "desc")
    );

    var notesList = [];

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      notesList.push({ id: doc.id, ...doc.data() });
    });

    setNotes(notesList);
  };

  useEffect(() => {
    fetchNotes();
  }, []);


  const addCoachNote = async (e: { preventDefault: () => void; target }) => {
    e.preventDefault();

    const date = new Date();

    //add note to firestore
    const note = {
      uid: uid,
      email: email,
      name: name,
      coachUid: coachUid,
      coachEmail: coachEmail,
      coachName: coachName,
      category: category.length > 0 ? category : ["Other"],
      note: noteText,
      date: date,
    };

    await addDoc(collection(getFirestore(), "coach_notes"), note);

    e.target.reset();
    setAddNoteOpen(false);
    setCategory([]);
    fetchNotes();
  };

  const deleteCoachNote = async (id) => {
    await deleteDoc(doc(getFirestore(), "coach_notes", id));
    fetchNotes();
  }

  return (
    <>
      <IonCard className="ion-padding">
        <IonCardHeader>
          <IonToolbar className="remove-bg">
            <IonCardTitle className="ion-text-center">Notes</IonCardTitle>
          </IonToolbar>
          <IonButton onClick={() => setAddNoteOpen(true)}>Add Notes</IonButton>
        </IonCardHeader>
        <IonCardContent>
          {notes.length > 0 ? null : (
            <>
              <p>No Notes</p>
            </>
          )}
          <RenderNotes />
        </IonCardContent>
      </IonCard>

      <IonModal isOpen={addNoteOpen}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Add Note</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setAddNoteOpen(false)}>
                Cancel
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <form onSubmit={addCoachNote}>
          <IonItem>
            <IonLabel position="floating">Catagories</IonLabel>
            <IonSelect multiple  onIonChange={(e) => setCategory(e.detail.value)}>
              <IonSelectOption value="Demographics">Demographics</IonSelectOption>
              <IonSelectOption value="Medical Hx">Medical Hx</IonSelectOption>
              <IonSelectOption value="Medications/Supplements">Medications/Supplements</IonSelectOption>
              <IonSelectOption value="Biometrics">Biometrics</IonSelectOption>
              <IonSelectOption value="Activity">Activity</IonSelectOption>
              <IonSelectOption value="Goal Update">Goal Update</IonSelectOption>
              <IonSelectOption value="Personal Life">Personal Life</IonSelectOption>
              <IonSelectOption value="Other">Other</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel position="floating">Note</IonLabel>
            <IonTextarea
              name="note"
              onIonChange={(e) => setNoteText(e.detail.value)}
              className="noteInputBox"
              required={true}
              autoGrow={true}
            ></IonTextarea>
          </IonItem>

          <IonButton expand="block" type="submit">
            Submit
          </IonButton>
        </form>
      </IonModal>
    </>
  );
}
