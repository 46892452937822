//tempalte for component in react
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonInput,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { doc, getFirestore, writeBatch } from "firebase/firestore";
import React from "react";

export default function AddGroup() {
  const [group, setGroup] = React.useState<string>();
  const [groupID, setGroupID] = React.useState<string>();
  const [walmartHealthPlanCode, setWalmartHealthPlanCode] =
    React.useState<string>();

  const walmartHealthPlanCodes = (
    <>
      <IonSelectOption value={process.env.REACT_APP_WH_PLANCODE_1}>
        {process.env.REACT_APP_WH_PLAN_TITLE_1}
      </IonSelectOption>
      <IonSelectOption value={process.env.REACT_APP_WH_PLANCODE_2}>
        {process.env.REACT_APP_WH_PLAN_TITLE_2}
      </IonSelectOption>
      <IonSelectOption value={process.env.REACT_APP_WH_PLANCODE_3}>
        {process.env.REACT_APP_WH_PLAN_TITLE_3}
      </IonSelectOption>
    </>
  );

  // this is what handles when the from is submited
  const addGroup = async (e: { preventDefault: () => void; target }) => {
    e.preventDefault();

    const userDoc = doc(getFirestore(), "groups", groupID);

    // Commit both docs together as a batch write.
    const batch = writeBatch(getFirestore());

    const groupObj = {
      group: group,
      groupID: groupID,
      active: true,
      walmartHealthPlanCode:
        walmartHealthPlanCode === undefined
          ? process.env.REACT_APP_WH_PLANCODE_1
          : walmartHealthPlanCode,
    };

    batch.set(userDoc, groupObj);
    if (walmartHealthPlanCode === undefined) {
      alert("You must select a Walmart Health Plan Code");
    } else {
      batch.commit();
    }
    console.log("group", groupObj);

    e.target.reset();
  };

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>Add Group</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <form onSubmit={addGroup}>
          <IonItem>
            <IonLabel position="stacked">Group Name</IonLabel>
            <IonInput
              required
              placeholder=""
              onIonChange={(e) => setGroup(e.detail.value)}
            ></IonInput>
          </IonItem>

          <IonItem>
            <IonLabel position="stacked">Group ID</IonLabel>
            <IonInput
              required
              placeholder=""
              onIonChange={(e) => setGroupID(e.detail.value)}
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Walmart Health Plan Code</IonLabel>
            <IonSelect
              onIonChange={(e) => setWalmartHealthPlanCode(e.detail.value)}
            >
              {walmartHealthPlanCodes}
            </IonSelect>
          </IonItem>

          <div id="add-group-help"></div>

          <IonButton type="submit">Add Group</IonButton>
        </form>
      </IonCardContent>
    </IonCard>
  );
}
