import { IonCard, IonItem } from "@ionic/react";
import CompliantHistory from "../CompliantHistory";

export default function CompletedCompliantCard() {
  return (
    <>
      <IonCard>
        <IonItem>
          <b style={{color: "green"}}>MEMBER IS COMPLIANT</b>
        <CompliantHistory />
        </IonItem>
      </IonCard>
    </>
  );
}
