import {
  IonList,
  IonItem,
  IonBadge,
  IonLabel,
  IonCard,
  IonCardTitle,
} from "@ionic/react";
import { useState, useEffect } from "react";
import { fetchAllMetrics } from "../../../../API/metrics/AllMetricsAPI";
import { MetricsDataTypes } from "../../../../Types/metrics";

export default function AllUsersMetrics() {
  const [allMetrics, setAllMetrics] = useState<MetricsDataTypes | null>(null);

  useEffect(() => {
    async function fetchMetrics() {
      const response = await fetchAllMetrics();
      setAllMetrics(response);
    }
    fetchMetrics();
  }, []);

  return (
    <>
      <IonCard>
        <IonCardTitle>ALL GROUPS</IonCardTitle>
        {allMetrics && (
          <>
            <IonList>
              <IonItem>
                <IonBadge slot="end">{allMetrics.totalUsers}</IonBadge>
                <IonLabel>Total</IonLabel>
              </IonItem>
              <IonItem>
                <IonBadge slot="end">{allMetrics.totalActiveUsers}</IonBadge>
                <IonLabel>Active</IonLabel>
              </IonItem>
              <IonItem>
                <IonBadge slot="end">{allMetrics.totalNonActiveUsers}</IonBadge>
                <IonLabel>Non-Active</IonLabel>
              </IonItem>
              <IonItem>
                <IonBadge slot="end">{allMetrics.totalCompletedHRA}</IonBadge>
                <IonLabel>Completed HRA</IonLabel>
              </IonItem>
              <IonItem>
                <IonBadge slot="end">
                  {allMetrics.totalNonCompletedHRA}
                </IonBadge>
                <IonLabel>Non-Complete HRA</IonLabel>
              </IonItem>
              <IonItem>
                <IonBadge slot="end">
                  {allMetrics.totalMonthlyCompliant}
                </IonBadge>
                <IonLabel>This Month Compliant</IonLabel>
              </IonItem>
            </IonList>
          </>
        )}
      </IonCard>
    </>
  );
}
