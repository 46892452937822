import { collection, getFirestore, getDocs } from "@firebase/firestore";
import { userType } from "../../Types/user";
import { MetricsDataTypes } from "../../Types/metrics";

export async function fetchAllMetrics(): Promise<MetricsDataTypes> {
  try {
    const allUsersQ = collection(getFirestore(), "users");
    const allUsersSnapshot = await getDocs(allUsersQ);

    const allUsersData = allUsersSnapshot.docs.map((doc) => {
      const data = doc.data() as userType;
      return { id: doc.id, ...data };
    });

    const activeUsersData = allUsersData.filter((user) => user.active);
    const completedHRAData = allUsersData.filter((user) => user.hraComplete);
    const compliantUsersData = allUsersData.filter((user) => user.compliant);

    return {
      totalUsers: allUsersData.length,
      totalActiveUsers: activeUsersData.length,
      totalNonActiveUsers: allUsersData.length - activeUsersData.length,
      totalCompletedHRA: completedHRAData.length,
      totalNonCompletedHRA: allUsersData.length - completedHRAData.length,
      totalMonthlyCompliant: compliantUsersData.length,
    };
  } catch (error) {
    console.error("AllMetricsAPI.ts", error);
    return {
      totalUsers: 0,
      totalActiveUsers: 0,
      totalNonActiveUsers: 0,
      totalCompletedHRA: 0,
      totalNonCompletedHRA: 0,
      totalMonthlyCompliant: 0,
    };
  }
}
