import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonMenuToggle,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useUserData } from "../../lib/hooks";
import "./Eligibility.css";
//import spinner
import React from "react";
import AddCoach from "../../components/Add/AddCoach";
import AddGroup from "../../components/Add/AddGroup";
import AddGroupCSV from "../../components/Add/AddGroupCSV";
import AddIndividualMember from "../../components/Add/AddIndividualMember";
import UploadEligibilityFile from "../../components/Eligibility/UploadEligibilityFile";

const Add: React.FC = () => {
  const userData = useUserData();

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuToggle>
                <IonMenuButton />
              </IonMenuToggle>
            </IonButtons>
            <IonTitle>Eligibility</IonTitle>
          </IonToolbar>
        </IonHeader>
        <UploadEligibilityFile />
      </IonContent>
    </IonPage>
  );
};

export default Add;
