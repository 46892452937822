import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonMenuToggle,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
//import spinner
import React from "react";
import ComplianceReport from "../../components/Reporting/ComplianceReport";
import QuestisReport from "../../components/Reporting/QuestisReport";
import Bodee from "./Bodee";
import BodeeDataReport from "../../components/Reporting/BodeeDataReport";
import BodeePublicAvatarReport from "../../components/Reporting/BodeePublicAvatarReport";

const Reporting: React.FC = () => {


  return (
    <IonPage>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuToggle>
                <IonMenuButton />
              </IonMenuToggle>
            </IonButtons>
            <IonTitle>Reporting</IonTitle>
          </IonToolbar>
        </IonHeader>
        <BodeeDataReport />
        <BodeePublicAvatarReport />
      </IonContent>
    </IonPage>
  );
};

export default Reporting;
