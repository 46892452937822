import { CometChat } from "@cometchat-pro/chat";
import { IonPage, IonContent, IonButton } from "@ionic/react";
import { auth } from "../../lib/firebase";

const NotAllowed: React.FC = () => {
  const logOut = async () => {
    CometChat.logout();
    auth.signOut();
    window.location.href = "/login";
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <p>Not Allowed</p>
        <IonButton onClick={logOut}>Log Out</IonButton>
      </IonContent>
    </IonPage>
  );
};

export default NotAllowed;
