import { IonApp, setupIonicReact } from "@ionic/react";
import PublicRoutes from "./pages/public/PublicRouter";
import PrivateRoutes from "./pages/private/Router/PrivateRouter";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css"; 



import { UserContext } from "./lib/context";
import { useUserData } from "./lib/hooks";

import { CometChat } from "@cometchat-pro/chat";

setupIonicReact();


const App = () => {
  const userData = useUserData();

  return (
    <>
      <UserContext.Provider value={userData}>
        <IonApp>{userData.user ? <PrivateRoutes /> : <PublicRoutes />}</IonApp>
      </UserContext.Provider>
    </>
  );
};

export default App;
