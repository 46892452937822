import { useEffect, useState } from "react";
import "./style.css";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import QuestionCard from "../QuestionCard";

type Props = {};

const IndividualQuestion: React.FC<Props> = () => {
  // get last part of url
  const [questionId, setQuestionId] = useState(
    window.location.pathname.split("/").pop()
  );

  const [question, setQuestion] = useState(null);

  const fetchQuestion = async () => {
    getDoc(doc(getFirestore(), "koroQuestions", questionId)).then((doc) => {
      if (doc.exists()) {
        setQuestion({id: doc.id, ...doc.data()});
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    });
  };

  // fetch question on page load
  useEffect(() => {
    fetchQuestion();
  }, []);

  if (!question) {
    return <div>loading...</div>;
  }

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Question</IonTitle>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/page/bodee" />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="backgroundColor">
          <QuestionCard
            question={question}
            refresh={null}
            key={question.id}
            refreshHandler={null}
          />
        </IonContent>
      </IonPage>
    </>
  );
};

export default IndividualQuestion;
