import {
  IonItem,
  IonIcon,
  IonToggle,
  IonLabel,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
  IonAvatar,
  IonCol,
  IonGrid,
  IonRow,
  IonTextarea,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import {
  arrowUp,
  arrowDown,
  shareSocial,
  chatbox,
  chatboxOutline,
  cropOutline,
  closeOutline,
  paperPlane,
  refresh,
} from "ionicons/icons";
import "./style.css";
import { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../../../lib/context";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

import Comment from "../Comment";
import { timeSince } from "../../../../lib/Helpers/time";
import { CommentInterface } from "../../../../Interfaces/CommentInterface";
import Reply from "../Reply";
import { get } from "http";

type Props = {
  downvotes;
  upvotes;
  share;
  id;
  comments;
  questionHandler;
  question;
};

const Toolbar: React.FC<Props> = ({
  downvotes,
  upvotes,
  share,
  id,
  comments,
  questionHandler,
  question,
}) => {
  const { profile } = useContext(UserContext);
  const [upvotesTracker, setUpvotesTracker] = useState(upvotes);
  const [downvotesTracker, setDownvotesTracker] = useState(downvotes);
  const [commentsOpen, setCommentsOpen] = useState(false);

  const [commentsData, setCommentsData] = useState([]) as any;

  const [comment, setComment] = useState("");
  const [replies, setReplies] = useState([]) as any;

  const [isThereExpertReply, setIsThereExpertReply] = useState(false);

  const [reply, setReply] = useState({});

  const inputRef = useRef<any>(null);

  const [commentSegment, setCommentSegment] = useState("expert");

  const [questionData, setQuestionData] = useState(question);

  const replyHandler = (r) => {
    console.log("");
    setReply(r);
    setComment(`@${r.name} `);
    inputRef?.current?.setFocus();
  };

  const upVote = async () => {
    var upvotesData = upvotesTracker;
    var downvotesData = downvotesTracker;

    if (upvotesTracker.includes(profile.uid)) {
      const newUpvotesTracker = upvotesTracker.filter(
        (upvote) => upvote !== profile.uid
      );
      setUpvotesTracker(newUpvotesTracker);
      upvotesData = newUpvotesTracker;
    } else {
      setUpvotesTracker([...upvotesTracker, profile.uid]);
      upvotesData = [...upvotesTracker, profile.uid];
      // remove from downvotesTracker if it exists
      if (downvotesTracker.includes(profile.uid)) {
        const newDownvotesTracker = downvotesTracker.filter(
          (downvote) => downvote !== profile.uid
        );
        setDownvotesTracker(newDownvotesTracker);
        downvotesData = newDownvotesTracker;
      }
    }

    const docRef = doc(getFirestore(), "koroQuestions", id);

    await updateDoc(docRef, {
      upvotes: upvotesData,
      downvotes: downvotesData,
    })
      .then(() => {
        console.log("Document successfully updated!");
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  };

  const downVote = async () => {
    var upvotesData = upvotesTracker;
    var downvotesData = downvotesTracker;

    if (downvotesTracker.includes(profile.uid)) {
      const newDownvotesTracker = downvotesTracker.filter(
        (downvote) => downvote !== profile.uid
      );
      setDownvotesTracker(newDownvotesTracker);
      downvotesData = newDownvotesTracker;
    } else {
      setDownvotesTracker([...downvotesTracker, profile.uid]);
      downvotesData = [...downvotesTracker, profile.uid];
      // remove from upvotesTracker if it exists
      if (upvotesTracker.includes(profile.uid)) {
        const newUpvotesTracker = upvotesTracker.filter(
          (upvote) => upvote !== profile.uid
        );
        setUpvotesTracker(newUpvotesTracker);
        upvotesData = newUpvotesTracker;
      }
    }

    const docRef = doc(getFirestore(), "koroQuestions", id);

    await updateDoc(docRef, {
      upvotes: upvotesData,
      downvotes: downvotesData,
    });
  };

  const postComment = () => {
    // console.log(comment);
    console.log("adddddonmg ")
    const data: CommentInterface = {
      avatar: profile.photoURL,
      name: profile.firstName + " " + profile.lastName,
      timestamp: new Date(),
      comment: comment,
      postId: id,
      poster: profile.uid,
      reply: false,
      replyTo: "",
      replyFrom: "",
      parentCommentId: "",
      expert: true,
    };

    const maxByteSize = 70000;
    console.log("maxByteSize", maxByteSize);

    const jsonComment = JSON.stringify(data.comment);
    const bytesComment = new Blob([jsonComment]).size;
    console.log("bytesComment", bytesComment);

    var json = JSON.stringify(data);
    var bytes = new Blob([json]).size;
    console.log("bytesTotal", bytes);

    if (bytes > maxByteSize) {
      const bytesLeftToRemove = bytes - maxByteSize;
      const percentToRemove = bytesLeftToRemove / bytes;

      alert(
        `Your comment is too long. Please remove ${
          percentToRemove * 100
        }% of your comment. ${bytesLeftToRemove} bytes.`
      );
      return;
    }

    if (comment.includes("@")) {
      data.reply = true;
      data.replyTo = reply["publicAvatarUid"];
      data.replyFrom = profile.id;
      data.parentCommentId = reply["parentCommentId"];
    }


    addDoc(collection(getFirestore(), "koroComments"), data).then((docRef) => {
      console.log("Answer addddddded");
      setComment("");
      data.id = docRef.id;

      if (questionData.status === "waitingForAnswer") {
        console.log("HERE is HERE")
        setQuestionData({ ...questionData, status: "waitingForMember" });
        questionHandler({ ...questionData, status: "waitingForMember" });
      }

      if (data.reply) {
        setReplies([...replies, data]);
      } else {
        setCommentsData([...commentsData, data]);
      }
    });
  };

  const getComments = async () => {
    var comments = [];

    const q = query(
      collection(getFirestore(), "koroComments"),
      where("postId", "==", id)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      comments.push({ id: doc.id, ...doc.data() });
    });


    // find all comments that have deleted === true and remove them
    comments = comments.filter((comment) => comment.deleted !== true);

    // order comments by timestamp but put expert == true at the top but ignore if it's a reply
    comments.sort((a, b) => {
      if (a.expert && !b.expert) {
        return -1;
      } else if (!a.expert && b.expert) {
        return 1;
      } else {
        return a.timestamp.seconds - b.timestamp.seconds;
      }
    });

    // comments.sort((a, b) => {
    //   return a.timestamp.seconds - b.timestamp.seconds;
    // });

    comments.sort((a, b) => {
      if (a.expert && !b.expert) {
        return -1;
      } else if (!a.expert && b.expert) {
        return 1;
      } else {
        return (
          (b.upvotes?.length || 0) -
          (b.downvotes?.length || 0) -
          ((a.upvotes?.length || 0) - (a.downvotes?.length || 0))
        );
      }
    });

    var rep = [];

    comments.forEach((comment) => {
      if (comment.reply) {
        rep.push(comment);
        // remove from comments
        comments = comments.filter((c) => c.id !== comment.id);
      }
    });

    // check to see if there is an expert reply
    comments.forEach((comment) => {
      if (comment.expert) {
        setIsThereExpertReply(true);
      }
    });

    rep.forEach((reply) => {
      const parentComment = comments.find(
        (comment) => comment.id === reply.parentCommentId
      );

      if (!parentComment) {
        rep = rep.filter((r) => r.id !== reply.id);
      }
    });

    setCommentsData(comments);
    setReplies(rep);
  };

  useEffect(() => {
    getComments();
  }, [refresh]);

  // useEffect(() => {
  //   setTimeout(() => inputRef?.current?.setFocus(), 700);
  // }, [commentsOpen]);

  const questionHandle = (question) => {
    console.log("questionHandler!", question);
    getComments();
    setQuestionData(question);
  };

  return (
    <>
      <IonItem className="koroQuestionToolbar ion-margin" lines="none">
        <span className="koroQuestionToolbarWords">
          <IonIcon
            icon={arrowUp}
            onClick={upVote}
            color={upvotesTracker.includes(profile?.uid) ? "primary" : ""}
          />{" "}
          <span
            className={
              upvotesTracker.includes(profile?.uid) ? "colorPrimary" : ""
            }
          >
            {upvotesTracker.length}
          </span>
        </span>
        <span className="ion-margin"></span>
        <span className="koroQuestionToolbarWords">
          <IonIcon
            icon={arrowDown}
            onClick={downVote}
            color={downvotesTracker.includes(profile?.uid) ? "primary" : ""}
          />{" "}
          <span
            className={
              downvotesTracker.includes(profile?.uid) ? "colorPrimary" : ""
            }
          >
            {downvotesTracker.length}
          </span>
        </span>
        <span className="ion-margin"></span>
        <span className="koroQuestionToolbarWords">
          <IonIcon
            icon={chatboxOutline}
            onClick={() => setCommentsOpen(true)}
          />{" "}
          {commentsData.length + replies.length}
        </span>
      </IonItem>

      <IonModal
        isOpen={commentsOpen}
        onDidDismiss={() => setCommentsOpen(false)}
        className="fullscreenModal"
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>Comments</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setCommentsOpen(false)}>
                Close
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {/* {reply["publicAvatarUid"]}
          <br></br>
          {reply["name"]}
          <br></br>

          {reply["parentCommentId"]} */}
          <IonSegment
            value={commentSegment}
            onIonChange={(e) => setCommentSegment(e.detail.value)}
          >
            <IonSegmentButton value="expert">
              <IonLabel>Expert</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="member">
              <IonLabel>Member</IonLabel>
            </IonSegmentButton>
          </IonSegment>

          {commentSegment === "expert" ? (
            <IonList className="ion-padding-top">
              {!isThereExpertReply && (
                <>
                  <IonLabel>
                    <p className="ion-text-center">No expert replies yet</p>
                  </IonLabel>
                </>
              )}
              {commentsData.map((comment) => {
                if (comment.expert) {
                  return (
                    <div key={comment.id}>
                      <Comment
                        avatar={comment.avatar}
                        name={comment.name}
                        time={timeSince(comment.timestamp)}
                        comment={comment.comment}
                        expert={comment.expert}
                        publicAvatarUid={comment.poster}
                        replyHandler={replyHandler}
                        id={comment.id}
                        question={questionData}
                        allowReply={false}
                        commentObject={comment}
                        questionHandler={questionHandle}
                        answer={questionData.answer === comment.comment}
                      />
                      {replies.map((reply) => {
                        // console.log(reply.replyTo, comment.poster, reply.comment)
                        if (reply.parentCommentId === comment.id) {
                          return (
                            <div key={reply.id}>
                              <Reply
                                avatar={reply.avatar}
                                key={reply.id}
                                name={reply.name}
                                time={timeSince(reply.timestamp)}
                                comment={reply.comment}
                                publicAvatarUid={reply.poster}
                                expert={reply.expert}
                                allowReply={false}
                                questionHandler={questionHandle}
                                replyHandler={replyHandler}
                                id={reply.parentCommentId}
                              />
                            </div>
                          );
                        }
                      })}
                    </div>
                  );
                }
              })}
            </IonList>
          ) : (
            <IonList className="ion-padding-top">
              {!commentsData.length && (
                <>
                  <IonLabel>
                    <p className="ion-text-center">No member replies yet</p>
                  </IonLabel>
                </>
              )}
              {commentsData.map((comment) => {
                if (!comment.expert) {
                  return (
                    <div key={comment.id}>
                      <Comment
                        avatar={comment.avatar}
                        name={comment.name}
                        time={timeSince(comment.timestamp)}
                        comment={comment.comment}
                        expert={comment.expert}
                        publicAvatarUid={comment.poster}
                        replyHandler={replyHandler}
                        allowReply={true}
                        id={comment.id}
                        question={questionData}
                        questionHandler={questionHandle}
                        commentObject={comment}
                        answer={questionData.answer === comment.comment}
                      />
                      {replies.map((reply) => {
                        // console.log(reply.replyTo, comment.poster, reply.comment)
                        if (reply.parentCommentId === comment.id) {
                          return (
                            <div key={reply.id}>
                              <Reply
                                avatar={reply.avatar}
                                key={reply.id}
                                name={reply.name}
                                time={timeSince(reply.timestamp)}
                                comment={reply.comment}
                                publicAvatarUid={reply.poster}
                                expert={reply.expert}
                                replyHandler={replyHandler}
                                allowReply={true}
                                questionHandler={questionHandle}
                                id={reply.parentCommentId}
                              />
                            </div>
                          );
                        }
                      })}
                    </div>
                  );
                }
              })}
            </IonList>
          )}

          {/* {reply["publicAvatarUid"]}
  {reply["name"]} */}

          {commentSegment !== "member" && (
            <>
              {questionData.status === "waitingForMember" ? (
                <>Coach comments are turned off until member submits response</>
              ) : (
                <>
                  <IonItem lines="none">
                    <IonGrid>
                      <IonRow>
                        <IonCol size="2">
                          <IonAvatar className="">
                            <img src={profile?.photoURL} />
                          </IonAvatar>
                        </IonCol>

                        <IonCol>
                          <div className="inputItemFormComment">
                            {/* <span>@{profile.displayName}</span> */}
                            <IonTextarea
                              placeholder="Your comment..."
                              required={true}
                              autoGrow={true}
                              value={comment}
                              // autofocus={true}
                              onIonBlur={() => console.log("BLUR")}
                              onIonFocus={() => console.log("FOCUS")}
                              onIonInput={(e: any) =>
                                setComment(e.target.value)
                              }
                              // ref={(ref) => (inputRef.current = ref)}
                            ></IonTextarea>
                          </div>
                        </IonCol>
                        <IonCol size="1"></IonCol>
                      </IonRow>
                      <IonRow>
                        <IonRow></IonRow>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                  <IonItem
                    lines="none"
                    className="ion-margin-end ion-padding-end"
                  >
                    {comment !== "" && (
                      <>
                        <IonIcon
                          icon={paperPlane}
                          slot="end"
                          onClick={postComment}
                          className="sendButton"
                          size="large"
                          color="primary"
                        ></IonIcon>
                      </>
                    )}
                  </IonItem>
                </>
              )}
            </>
          )}
        </IonContent>
      </IonModal>
    </>
  );
};

export default Toolbar;
