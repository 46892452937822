import {
  IonItem,
  IonAvatar,
  IonLabel,
  IonIcon,
  IonCol,
  useIonAlert,
} from "@ionic/react";
import "./style.css";
import Avatar from "../Avatar";
import { useContext, useRef, useState } from "react";
import { UserContext } from "../../../../lib/context";
import { arrowDown, arrowUp, sparklesSharp, trashBin } from "ionicons/icons";
import { doc, getDoc, getFirestore, updateDoc } from "firebase/firestore";
import { get } from "http";

type Props = {
  avatar;
  name;
  comment;
  time;
  expert;
  publicAvatarUid;
  replyHandler;
  id;
  answer;
  commentObject;
  question;
  questionHandler;
  allowReply;
};

const Comment: React.FC<Props> = ({
  avatar,
  name,
  comment,
  time,
  expert,
  publicAvatarUid,
  question,
  replyHandler,
  commentObject,
  id,
  questionHandler,
  answer,
  allowReply,
}) => {
  // const [reply, setReply] = useState("");
  const { user, profile } = useContext(UserContext);
  const [presentAlert] = useIonAlert();
  const [questionData, setQuestionData] = useState(question);

  const [commentData, setCommentData] = useState(commentObject);

  const [upvotesTracker, setUpvotesTracker] = useState(
    commentObject.upvotes || []
  );
  const [downvotesTracker, setDownvotesTracker] = useState(
    commentObject.downvotes || []
  );

  const upVote = async () => {
    var upvotesData = upvotesTracker;
    var downvotesData = downvotesTracker;

    if (upvotesTracker.includes(profile.uid)) {
      const newUpvotesTracker = upvotesTracker.filter(
        (upvote) => upvote !== profile.uid
      );
      setUpvotesTracker(newUpvotesTracker);
      upvotesData = newUpvotesTracker;
    } else {
      setUpvotesTracker([...upvotesTracker, profile.uid]);
      upvotesData = [...upvotesTracker, profile.uid];
      // remove from downvotesTracker if it exists
      if (downvotesTracker.includes(profile.uid)) {
        const newDownvotesTracker = downvotesTracker.filter(
          (downvote) => downvote !== profile.uid
        );
        setDownvotesTracker(newDownvotesTracker);
        downvotesData = newDownvotesTracker;
      }
    }

    const docRef = doc(getFirestore(), "koroComments", id);

    await updateDoc(docRef, {
      upvotes: upvotesData,
      downvotes: downvotesData,
    })
      .then(() => {
        console.log("Document successfully updated!");
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  };

  const downVote = async () => {
    var upvotesData = upvotesTracker;
    var downvotesData = downvotesTracker;

    if (downvotesTracker.includes(profile.uid)) {
      const newDownvotesTracker = downvotesTracker.filter(
        (downvote) => downvote !== profile.uid
      );
      setDownvotesTracker(newDownvotesTracker);
      downvotesData = newDownvotesTracker;
    } else {
      setDownvotesTracker([...downvotesTracker, profile.uid]);
      downvotesData = [...downvotesTracker, profile.uid];
      // remove from upvotesTracker if it exists
      if (upvotesTracker.includes(profile.uid)) {
        const newUpvotesTracker = upvotesTracker.filter(
          (upvote) => upvote !== profile.uid
        );
        setUpvotesTracker(newUpvotesTracker);
        upvotesData = newUpvotesTracker;
      }
    }

    const docRef = doc(getFirestore(), "koroComments", id);

    await updateDoc(docRef, {
      upvotes: upvotesData,
      downvotes: downvotesData,
    });
  };

  const deleteComment = async () => {
    console.log("delete comment", id);
    const docRef = doc(getFirestore(), "koroComments", id);
    const questionRef = doc(getFirestore(), "koroQuestions", question.id);

    getDoc(questionRef).then((doc) => {
      if (doc.exists()) {
        if (comment === doc.data().answer) {
          alert("You cannot delete the answer to a question");
          return;
        }
        else if (doc.data().status === "waitingForMember" && doc.data().attemptedAnswer === comment) {
          updateDoc(questionRef, {
            attemptedAnswer: null,
            attemptedAnswerCoach: null,
            attemptedDateAnswer: null,
            status: "waitingForAnswer",
          }).then(() => {
            console.log("Document successfully updated!");
            updateDoc(docRef, {
              deleted: true,
            }).then(() => {
              const newQuestion = question;
              newQuestion.attemptedAnswer = null;
              newQuestion.attemptedAnswerCoach = null;
              newQuestion.attemptedDateAnswer = null;
              newQuestion.status = "waitingForAnswer";
              setCommentData({ ...commentData, deleted: true });
              questionHandler(newQuestion);
            });
          });
        }

        else {
          updateDoc(docRef, {
            deleted: true,
          }).then(() => {
            console.log("Document successfully updated!");
            setCommentData({ ...commentData, deleted: true });
          });
        }
      }
    });

    

  };

  if (commentData.deleted === true) {
    return <></>;
  }

  return (
    <IonItem className=" koroQuestionAnswerHeaderItem border" lines="none">
      <Avatar src={avatar} publicAvatarUid={publicAvatarUid} expert={expert} />
      <IonLabel>
        <h2 className="ion-text-wrap">
          {answer && (
            <span className="bold">
              <IonIcon icon={sparklesSharp} color="primary"></IonIcon>
            </span>
          )}{" "}
          <span className="bold">{name}</span> ·{" "}
          {expert && (
            <>
              <span className="expert">Expert</span> ·
            </>
          )}
          <span className="koroQuestionAnswerTime">{time}</span>
          <span className="ion-margin">
            {publicAvatarUid === user.uid && (
              <IonIcon
                icon={trashBin}
                onClick={() =>
                  presentAlert({
                    header: "Delete Comment",
                    message: "Are you sure you want to delete this comment?",
                    buttons: [
                      {
                        text: "Cancel",
                        role: "cancel",
                        handler: () => {
                          console.log("Cancel clicked");
                        },
                      },
                      {
                        text: "OK",
                        role: "confirm",
                        handler: () => {
                          deleteComment();
                        },
                      },
                    ],
                  })
                }
              />
            )}
          </span>
        </h2>

        <p className="ion-text-wrap">{comment}</p>
        {/* <span className="replies">2 Replies {" · "}</span> */}
        {publicAvatarUid !== profile.uid && allowReply && (
          <span
            className="ion-text-wrap replyLink"
            onClick={() => {
              replyHandler({ publicAvatarUid, name, parentCommentId: id });
            }}
          >
            Reply
          </span>
        )}
      </IonLabel>
      <IonCol size="auto">
        <IonIcon
          icon={arrowUp}
          onClick={upVote}
          color={upvotesTracker.includes(profile?.uid) ? "primary" : ""}
        />{" "}
        <span
          className={
            upvotesTracker.includes(profile?.uid) ? "colorPrimary" : ""
          }
        >
          {upvotesTracker.length}
        </span>{" "}
        <IonIcon
          icon={arrowDown}
          onClick={downVote}
          color={downvotesTracker.includes(profile?.uid) ? "primary" : ""}
        />{" "}
        <span
          className={
            downvotesTracker.includes(profile?.uid) ? "colorPrimary" : ""
          }
        >
          {downvotesTracker.length}
        </span>
        <span className="ion-margin"></span>
      </IonCol>
    </IonItem>
  );
};

export default Comment;
