import { IonChip } from "@ionic/react";
import "./style.css";

type Props = {
  status: string;
}

const StatusChip: React.FC<Props> = ({status}) => {

  if (status === "answered") return (
    <IonChip className="status-chip-success" color="success">
      Answered
    </IonChip>
  );

  if (status === "waitingForMember") return (
    <IonChip className="status-chip-warning" >
      Waiting Member Response
    </IonChip>
  );

  if (status === "waitingForAnswer") return (
    <IonChip className="status-chip-danger" color="warning">
      Needs Answer
    </IonChip>
  );
};

export default StatusChip;