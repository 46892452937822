import { IonItem, IonAvatar, IonLabel } from "@ionic/react";
import "./style.css";
import { useContext } from "react";
import { UserContext } from "../../../../lib/context";
import Avatar from "../Avatar";
import AvatarMini from "../AvatarMini";

type Props = {
  avatar;
  name;
  time;
  comment;
  replyHandler;
  publicAvatarUid;
  expert;
  id;
  questionHandler;
  allowReply;
};

const Reply: React.FC<Props> = ({
  avatar,
  name,
  time,
  comment,
  replyHandler,
  publicAvatarUid,
  expert,
  allowReply,
  questionHandler,
  id,
}) => {
  const { profile } = useContext(UserContext);

  const renderAtInComment = (comment) => {
    let commentArray = comment.split(" ");
    let newCommentArray = [];
    commentArray.forEach((word) => {
      if (word.startsWith("@")) {
        newCommentArray.push(<span className="bold primary">{word}</span>);
      } else {
        newCommentArray.push(word);
      }
    });
    // return newCommentArray;
    return newCommentArray.map((word) => {
      return <>{word} </>;
    });
  };

  return (
    <IonItem className="koroQuestionAnswerSubHeaderItem" lines="none">
      {/* <IonAvatar className="koroQuestionAnswerAvatar">
        <img src={avatar} />
      </IonAvatar> */}
      <AvatarMini
        src={avatar}
        publicAvatarUid={publicAvatarUid}
        expert={expert}
      />

      <IonLabel>
        <h2>
          {" "}
          <span className="bold">{name}</span> ·{" "}
          {expert && (
            <>
              <span className="expert">Expert</span> ·
            </>
          )}
          <span className="koroQuestionAnswerTime">{time}</span>
        </h2>

        <p className="ion-text-wrap">{renderAtInComment(comment)}</p>
        {publicAvatarUid !== profile.uid && allowReply && (
          <p
            className="ion-text-wrap replyLink"
            onClick={() => {
              replyHandler({ publicAvatarUid, name, parentCommentId: id });
            }}
          >
            Reply
          </p>
        )}
        <IonLabel slot="end"></IonLabel>
      </IonLabel>
    </IonItem>
  );
};

export default Reply;
