import { IonLabel } from "@ionic/react";
import "./style.css";

type Props = {
  img;
  title;
  body;
};

const HeaderImgAndTitle: React.FC<Props> = ({ img, title, body }) => {
  return (
    <>
      {img === null ? null : (
        <div className="koroQuestionHeaderImgWrapper">
          <img className="koroQuestionHeaderImg" src={img} />
        </div>
      )}
      <div className="ion-margin">
          <h2 className="questionTitle ion-text-left">{title}</h2>
        <p className="koroQuestionBodyCard ion-text-left">{body}</p>
      </div>
    </>
  );
};

export default HeaderImgAndTitle;
