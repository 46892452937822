import {
  query,
  collection,
  getFirestore,
  where,
  getDocs,
} from "@firebase/firestore";
import { MetricsDataGroupTypes } from "../../Types/metrics";
import { userType } from "../../Types/user";
import { groupsTypes } from "../../Types/groups";

export async function fetchSelectedGroupMetrics(
  groupID
): Promise<MetricsDataGroupTypes> {
  try {
    const usersQ = query(
      collection(getFirestore(), "users"),
      where("groupID", "==", groupID)
    );
    const groupNameQ = query(
      collection(getFirestore(), "groups"),
      where("groupID", "==", groupID)
    );

    const groupsSnapshot = await getDocs(groupNameQ);

    const allGroupsData = groupsSnapshot.docs.map((doc) => {
      const data = doc.data() as groupsTypes;
      return { id: doc.id, ...data };
    });

    const groupName = allGroupsData[0].group;
    const groupActive = allGroupsData[0].active;

    const usersSnapshot = await getDocs(usersQ);

    const allUsersData = usersSnapshot.docs.map((doc) => {
      const data = doc.data() as userType;
      return { id: doc.id, ...data };
    });

    const activeUsersData = allUsersData.filter((user) => user.active);
    const compliantUsersData = allUsersData.filter((user) => user.compliant);
    const completedHRAData = allUsersData.filter((user) => user.hraComplete);

    return {
      totalGroupUsers: allUsersData.length,
      totalGroupActiveUsers: activeUsersData.length,
      totalGroupNonActiveUsers: allUsersData.length - activeUsersData.length,
      totalGroupCompletedHRA: completedHRAData.length,
      totalGroupNonCompletedHRA: allUsersData.length - completedHRAData.length,
      totalGroupMonthlyCompliant: compliantUsersData.length,
      groupName: groupName,
      groupActive: groupActive,
    };
  } catch (error) {
    console.error(error);
    return {
      totalGroupUsers: 0,
      totalGroupActiveUsers: 0,
      totalGroupNonActiveUsers: 0,
      totalGroupCompletedHRA: 0,
      totalGroupNonCompletedHRA: 0,
      totalGroupMonthlyCompliant: 0,
      groupName: "",
      groupActive: null,
    };
  }
}
